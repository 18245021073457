import { omit } from 'ramda';
import queryParamsPersist from '../../../lib/queryParamsPersist';
import { validationSchema } from '../../../statics/validations/booking/parameters';
import { MERGE_PARAMETERS, SET_PARAMETERS } from './types';

export const setParameters = (payload) => {
  const parameters = validationSchema.validateSync(payload, { stripUnknown: true });
  queryParamsPersist.save(parameters);
  return {
    type   : SET_PARAMETERS,
    payload: parameters,
  };
};

export const mergeParameters = (payload) => {
  const parameters = validationSchema.validateSync(payload, { stripUnknown: true });
  const currParameters = queryParamsPersist.get();
  queryParamsPersist.save({ ...currParameters, ...parameters });
  return {
    type   : MERGE_PARAMETERS,
    payload: parameters,
  };
};

export const removeParametersByKey = (payload) => {
  const currParameters = queryParamsPersist.get();
  const properParameters = omit(payload, currParameters);
  queryParamsPersist.save(properParameters);
  return {
    type   : SET_PARAMETERS,
    payload: properParameters,
  };
};
