const init = ({ id }, tagManagerDenied = false) => {
  const noScript = (gtmId) => {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="//www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
    return noscript;
  };
  const scriptLayer = () => {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`;
    return script;
  };
  if (!tagManagerDenied) {
    document.head.appendChild(scriptLayer());
    document.body.appendChild(noScript(id));
  }
  window.dataLayer = window.dataLayer || [];
};

const push = (toTrack) => {
  if (!window.dataLayer) {
    // eslint-disable-next-line no-console
    console.warn('datalayer has not been initialized');
    return;
  }
  window.dataLayer.push(toTrack);
};

const GTM = {
  init,
  push,
};

export default GTM;
