export const FLIGHT_INFO_REQUEST = 'DATA/FLIGHT_INFO_REQUEST';
export const FLIGHT_INFO_SUCCESS = 'DATA/FLIGHT_INFO_SUCCESS';
export const FLIGHT_INFO_ERROR   = 'DATA/FLIGHT_INFO_ERROR';
export const FLIGHT_INFO_END     = 'DATA/FLIGHT_INFO_END';
export const FLIGHT_INFO_CLEAN   = 'DATA/FLIGHT_INFO_CLEAN';
export const FLIGHT_INFO_UPDATE  = 'DATA/FLIGHT_INFO_UPDATE';

export const TIME_SLOTS_PICKUP_REQUEST = 'DATA/TIME_SLOTS_PICKUP_REQUEST';
export const TIME_SLOTS_PICKUP_SUCCESS = 'DATA/TIME_SLOTS_PICKUP_SUCCESS';
export const TIME_SLOTS_PICKUP_ERROR   = 'DATA/TIME_SLOTS_PICKUP_ERROR';

export const TIME_SLOTS_DELIVERY_REQUEST = 'DATA/TIME_SLOTS_DELIVERY_REQUEST';
export const TIME_SLOTS_DELIVERY_SUCCESS = 'DATA/TIME_SLOTS_DELIVERY_SUCCESS';
export const TIME_SLOTS_DELIVERY_ERROR   = 'DATA/TIME_SLOTS_DELIVERY_ERROR';

export const FLIGHTS_REQUEST = 'DATA/FLIGHTS_REQUEST';
export const FLIGHTS_SUCCESS = 'DATA/FLIGHTS_SUCCESS';
export const FLIGHTS_ERROR   = 'DATA/FLIGHTS_ERROR';

export const TERMINAL_NUMBER_REQUEST = 'DATA/TERMINAL_NUMBER_REQUEST';
export const TERMINAL_NUMBER_SUCCESS = 'DATA/TERMINAL_NUMBER_SUCCESS';
export const TERMINAL_NUMBER_ERROR   = 'DATA/TERMINAL_NUMBER_ERROR';

export const TERMINALS_REQUEST = 'DATA/TERMINALS_REQUEST';
export const TERMINALS_SUCCESS = 'DATA/TERMINALS_SUCCESS';
export const TERMINALS_ERROR   = 'DATA/TERMINALS_ERROR';

export const POI_AIRPORT_REQUEST = 'DATA/POI_AIRPORT_REQUEST';
export const POI_AIRPORT_SUCCESS = 'DATA/POI_AIRPORT_SUCCESS';
export const POI_AIRPORT_ERROR   = 'DATA/POI_AIRPORT_ERROR';

export const AREA_REQUEST = 'DATA/AREA_REQUEST';
export const AREA_SUCCESS = 'DATA/AREA_SUCCESS';
export const AREA_ERROR   = 'DATA/AREA_ERROR';

export const PRICE_PRODUCT_REQUEST = 'DATA/PRICE_PRODUCT_REQUEST';
export const PRICE_PRODUCT_SUCCESS = 'DATA/PRICE_PRODUCT_SUCCESS';
export const PRICE_PRODUCT_ERROR   = 'DATA/PRICE_PRODUCT_ERROR';

export const IATA_REQUEST = 'DATA/IATA_REQUEST';
export const IATA_SUCCESS = 'DATA/IATA_SUCCESS';
export const IATA_ERROR   = 'DATA/IATA_ERROR';

export const AIRPORTS_LIST_REQUEST = 'DATA/AIRPORTS_LIST_REQUEST';
export const AIRPORTS_LIST_SUCCESS = 'DATA/AIRPORTS_LIST_SUCCESS';
export const AIRPORTS_LIST_ERROR   = 'DATA/AIRPORTS_LIST_ERROR';

export const AIRLINES_LIST_REQUEST = 'DATA/AIRLINES_LIST_REQUEST';
export const AIRLINES_LIST_SUCCESS = 'DATA/AIRLINES_LIST_SUCCESS';
export const AIRLINES_LIST_ERROR   = 'DATA/AIRLINES_LIST_ERROR';

export const CHECK_PROMOCODE_REQUEST     = 'DATA/CHECK_PROMOCODE_REQUEST';
export const CHECK_PROMOCODE_SET         = 'DATA/CHECK_PROMOCODE_SET';
export const CHECK_PROMOCODE_SET_EDITING = 'DATA/CHECK_PROMOCODE_SET_EDITING';
export const CHECK_PROMOCODE_SUCCESS     = 'DATA/CHECK_PROMOCODE_SUCCESS';
export const CHECK_PROMOCODE_ERROR       = 'DATA/CHECK_PROMOCODE_ERROR';
export const CHECK_PROMOCODE_CLEAN       = 'DATA/CHECK_PROMOCODE_CLEAN';
