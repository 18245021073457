import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { Notification, ICONS } from '@bagonboard/ui-kit';

import { NoticeContainer } from './styles';

export const NoticeHolidaysNotOperable = ({ enabled }) => enabled && (
  <NoticeContainer>
    <Notification
      content={<Translate value="Booking.noticeHolidaysNotOperable" />}
      icon={ICONS.WARNING}
      testId="noticeHolidays"
      type="warning"
    />
  </NoticeContainer>
);
NoticeHolidaysNotOperable.propTypes = {
  enabled: PropTypes.bool,
};

NoticeHolidaysNotOperable.defaultProps = {
  enabled: true,
};

export default NoticeHolidaysNotOperable;
