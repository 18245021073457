import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends PureComponent {
  state = {
    error    : null,
    errorInfo: null,
    eventId  : null,
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const { error, errorInfo, eventId } = this.state;
    const { children, ErrorComponent } = this.props;
    if (errorInfo) {
      return (
        <ErrorComponent
          error={error}
          componentStack={errorInfo.componentStack}
          eventId={eventId}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children      : PropTypes.node.isRequired,
  ErrorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
};

export default ErrorBoundary;
