import { pipe, prop } from '@bagonboard/wiseman';

export const parametersSelector = (state) => state.booking.parameters;

export const widgetAirlinesSelector = pipe(
  parametersSelector,
  prop('widget-airlines')
);

export const orderSelector = pipe(
  parametersSelector,
  prop('order')
);

export const biometricSelector = pipe(
  parametersSelector,
  prop('biometric')
);

export const tokenSelector = pipe(
  parametersSelector,
  prop('token')
);
