import styled from 'styled-components';
import { Icon } from '@bagonboard/ui-kit';
import { widths } from '../../styles/widths';

export const BackContainer = styled.div`
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;

  ${(props) => props.onClick && `
    &:hover {
      cursor: pointer;
    }
  `}
`;
BackContainer.displayName = 'BackContainer';

export const BackIcon = styled(Icon)`
    margin-right: ${widths.base / 2}px;
`;
BackIcon.displayName = 'BackIcon';

export const LogoContainer = styled.div`
  ${(props) => props.onClick && `
    &:hover {
      cursor: pointer;
    }
  `}
`;
LogoContainer.displayName = 'LogoContainer';

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    display: block;
  }
`;
CloseIcon.displayName = 'CloseIcon';
