import axios from '../axios';
import { encryptQueryParams } from '../encrypt-qs';

const instance = axios.getBackendInstance(15000);
const s3Instance = axios.getS3Instance(15000);

const isValidResponse = (code) => (code && code >= 200 && code < 300);

export const errors = {
  noResponse        : 'No response from server',
  noResults         : 'No results found',
  unavailableService: 'Unavailable Service',
  invalidParams     : 'Invalid parameters',
};

export const fetchRequest = async (url, params) => {
  const queryEncrypted = encryptQueryParams(params);
  const response = await instance.get(`${url}?${queryEncrypted}`);
  const { code, data } = response.data;
  if (!isValidResponse(code)) throw new Error(errors.noResults);
  return data;
};

export const fetchPost = async (url, body) => {
  const response = await instance.post(url, body);
  const { code, data } = response.data;
  if (!isValidResponse(code)) throw new Error(errors.noResults);
  return data;
};

export const fetchNoParams = async (url) => {
  const response = await instance.get(url);
  const { code, data } = response.data;
  if (!isValidResponse(code)) throw new Error(errors.noResponse);
  return data;
};

export const fetchPostNoValidations = async (url, body) => {
  const response = await instance.post(url, body);
  return response.data;
};

export const fetchFromS3 = async (url) => {
  const response = await s3Instance.get(url);
  const { status, data } = response;
  if (!isValidResponse(status)) throw new Error(errors.noResponse);
  return data;
};
