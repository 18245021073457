import React from 'react';
import PropTypes from 'prop-types';
import {
  ICONS,
  Icon,
  Popup,
  Notification,
} from '@bagonboard/ui-kit';
import { Translate, I18n } from 'react-redux-i18n';
import {
  ApartmentIcon,
  Container,
  PopupModal,
  PopupText,
  PopupFooterButton,
  AddNumberButton,
  Wrapper,
} from './style';

export const MissingNumberAddressPopUp = ({
  isOpen, onClose,
}) => (
  <Popup
    title={I18n.t('Booking.missingNumberAddress.title')}
    isOpen={isOpen}
    onClose={onClose}
  >
    <Wrapper>
      <Container data-test-id="missing-number-address-popup-container">
        <PopupModal>
          <Notification
            title={<Translate value="Booking.missingNumberAddress.subtitle" />}
            content={(
              <Translate
                value="Booking.missingNumberAddress.claim"
                action={I18n.t('Booking.missingNumberAddress.actionPickUp')}
              />
            )}
            type="error"
            flexDirection="column"
            width="311px"
            arrow
          />
          <ApartmentIcon>
            <Icon size={40} icon={ICONS.APARTMENT} viewBox="0 0 40 40" />
          </ApartmentIcon>
          <PopupText>
            {I18n.t('Booking.missingNumberAddress.iconFooter')}
          </PopupText>
          <AddNumberButton
            handleClick={onClose}
            text={I18n.t('Booking.missingNumberAddress.buttonText')}
          />
        </PopupModal>
        <PopupFooterButton
          handleClick={onClose}
          text={I18n.t('Booking.missingNumberAddress.buttonText')}
        />
      </Container>
    </Wrapper>
  </Popup>
);

MissingNumberAddressPopUp.propTypes = {
  isOpen : PropTypes.bool,
  onClose: PropTypes.func,
};

MissingNumberAddressPopUp.defaultProps = {
  isOpen : false,
  onClose: () => {},
};
