import { createGlobalStyle } from 'styled-components';
import { palette, font } from '@bagonboard/ui-kit';

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${palette.white};
    color: ${palette.bobBlack};
    font-family: ${font.montse};
    font-size: 14px;
    line-height: 22px;
    font-weight: ${font.regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
  a {
    text-decoration: none;
    color: ${palette.link};
    &:hover {
      outline: 0;
    }
    &:focus {
      color: ${palette.bobBlack};
      outline: 0;
    }
  }
  :focus {
    outline: '0 !important';
  }
  input,
  select,
  button {
    font-family: ${font.montse};
  }
  button:focus {
    outline: 0
  }
`;
