import { combineReducers } from 'redux';
import { i18nReducer as i18n } from 'react-redux-i18n';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { connectRouter } from 'connected-react-router';
import booking from './booking/reducer';
import data from './data/reducer';
import widget from './widget/reducer';
import history from '../history';
import pirInfo from './pirInfo';

const toCombine = {
  booking,
  data,
  widget,
  i18n,
  loadingBar,
  router: connectRouter(history),
  pirInfo,
};

const rootReducer = combineReducers(toCombine);

export default rootReducer;
