import { ProviderDate } from '@bagonboard/ui-kit';
import CONSTANTS from '../../../lib/constants';
import GTM from '../../../lib/GTM';
import { calculateFlightHaulType } from '../../../lib/flightLength';
import { findAirport } from '../../../lib/airportsList';

export const pushOrderToGTM = (products, type, airline, orderId, invoice) => {
  // Enhanced ecommerce data
  const {
    price, tax, detailProducts, sku,
  } = products[0];
  const ecommerceProducts = detailProducts.map((product, i) => ({
    name    : i === 0 ? 'first bag' : 'additional bag',
    // eslint-disable-next-line no-underscore-dangle
    id      : product._id,
    variant : sku,
    category: type,
    price   : product.price,
    quantity: product.quantity,
  }));

  const finalPrice = invoice && invoice.amount;
  const enhaceData = {
    event    : 'confirmationPage',
    ecommerce: {
      currencyCode: 'EUR',
      purchase    : {
        actionField: {
          id     : `${orderId}`,
          revenue: finalPrice || price,
          tax    : price * (tax / 100), // I dont like this, thoughs?? => copy/pasting, no thoughs,
          // cupon: , // TODO:
          // affiliation: '', // TODO:
        },
        products: ecommerceProducts,
      },
    },
  };
  GTM.push(enhaceData);
};

export const pushSelectedAddressToGTM = (selectedAddress) => {
  GTM.push({
    eventCategory: ' ops | address',
    eventAction  : 'autocomplete suggestion selected',
    eventLabel   : `${selectedAddress}`,
    event        : 'gaEvent',
  });
};

export const pushAddressErrorToGTM = (postalCode, amount) => {
  GTM.push({
    eventCategory: 'ops | out of area',
    eventAction  : 'city address',
    eventLabel   : `${postalCode}`,
    eventValue   : `${amount}`,
    event        : 'gaEvent',
  });
};

/**
 * Send variable to GTM with service opportunities summary
 * @param {Array} services array with info of flights and products shown to user
 */
export const pushServiceListToGTM = (services) => {
  GTM.push({
    serviceList: services.join(' | '),
  });
};

export const pushServiceTypeToGTM = (type) => {
  GTM.push({
    serviceType: type,
  });
};

export const pushAvailableSlotsToGTM = (name) => ({
  firstSlots = [], lastSlots = [], defaultSlots = [], firstDate, lastDate,
}) => {
  const firstDateSlots = firstSlots.filter((slot) => slot.available).length;
  const lastDateSlots = lastSlots.filter((slot) => slot.available).length;
  const defaultDatesSlots = defaultSlots.filter((slot) => slot.available).length;
  const days = ProviderDate.getDifferenceOfDays(firstDate, lastDate);
  const defaultDays = (days > 1) ? (days - 1) : 0;
  const availableDays = (!!firstDateSlots + !!lastDateSlots)
    + (defaultDatesSlots ? defaultDays : 0);
  const availableSlots = firstDateSlots + lastDateSlots + (defaultDays * defaultDatesSlots);
  GTM.push({
    [name]: `${availableDays} | ${availableSlots}`,
  });
};

export const pushPickupAvailableSlotsToGTM = pushAvailableSlotsToGTM('pickupAvailableSlots');
export const pushDeliveryAvailableSlotsToGTM = pushAvailableSlotsToGTM('deliveryAvailableSlots');

/**
 * @function pushOriginAndDestinationAirportToGTM
 * @param  {type} originAirport      Airport IATA code
 * @param  {type} destinationAirport Airport IATA code
 */
export const pushOriginAndDestinationAirportToGTM = (originAirport, destinationAirport) => {
  GTM.push({
    originAirport,
    destinationAirport,
  });
};

export const pushFlightRetrieveToGTM = (retrieveMethod) => {
  GTM.push({
    flightRetrieve: retrieveMethod,
  });
};

// save last flight searched to not repeat the calculation
let lastFligthSearched = '';
/**
 * Push flight length variable to Google Tag Manager
 * @param {Object[]} airportList list of world airports with coordinates
 * @param {String} fromCode origin IATA airport code
 * @param {String} toCode destination IATA airport code
 */
export const pushFlightLengthToGTM = async (airportList, fromCode, toCode) => {
  try {
    // Sort destinations alphabetically. Go and back should be the same distance so,
    // avoid doing same calculation again.
    // This condition can be removed, the only disadvantage will be unnecessary ops.
    const flightSearched = [fromCode, toCode].sort().join('-');
    if (flightSearched === lastFligthSearched) return;
    const { location: fromLocation } = findAirport(airportList, fromCode);
    const { location: toLocation } = findAirport(airportList, toCode);

    if (fromLocation && toLocation) {
      GTM.push({
        flightLength: calculateFlightHaulType(fromLocation, toLocation),
      });
      lastFligthSearched = flightSearched;
    }
  } catch (error) {
    // Silent crash
  }
};

export const pushDaysInAdvanceToGTM = (departureDate) => {
  const { moment, getDifferenceOfDays } = ProviderDate;
  const daysInAdvance = getDifferenceOfDays(moment(), departureDate);
  GTM.push({
    daysInAdvance: `${daysInAdvance}`, // They need a string 🤷‍♂️
  });
};

export const pushNumBagsToGTM = (paxs) => {
  const numBags = paxs.reduce((total, pax) => total + pax.numBags, 0);
  GTM.push({
    numBags: `${numBags}`,
  });
};

export const pushPageTypeToGTM = (path) => {
  const routePageTypes = {
    '/'                                     : 'flight search',
    '/router/pnr'                           : 'router pnr',
    '/router/from-to-date'                  : 'router dates',
    '/router/pnr/results'                   : 'router pnr results',
    '/router/pnr/no-results'                : 'router pnr no results',
    '/router/from-to-date/results'          : 'router dates results',
    '/router/from-to-date/no-results'       : 'router dates no results',
    '/router/checkin'                       : 'router service checkin',
    '/router/delivery/airport'              : 'router service transfer',
    '/router/delivery/city'                 : 'router service city delivery',
    '/steps'                                : 'steps bags pax no allowance',
    '/steps/bags/pax-no-allowance'          : 'steps bags pax no allowance',
    '/steps/bags/pax-allowance'             : 'steps bags pax allowance',
    '/steps/bags/no-pax'                    : 'steps bags no pax',
    '/steps/pickup-address'                 : 'steps pickup address',
    '/steps/pickup-address/out-of-service'  : 'steps pickup out of service',
    '/steps/pickup-time'                    : 'steps pickup time',
    '/steps/delivery-address'               : 'steps delivery address',
    '/steps/delivery-address/out-of-service': 'steps delivery out of service',
    '/steps/delivery-time'                  : 'steps delivery time',
    '/steps/contact-info/customer-known'    : 'contact info',
    '/steps/contact-info/customer-unknown'  : 'contact info',
    '/steps/payment'                        : 'payment',
    '/steps/payment-error'                  : 'payment error',
    '/confirmation/checkin'                 : 'booking confirmation',
    '/confirmation/delivery/airport'        : 'booking confirmation',
    '/confirmation/delivery/city'           : 'booking confirmation',
    '/confirmation/skipbaggageclaim'        : 'booking confirmation',
  };
  const pageTypeValue = routePageTypes[path];
  if (pageTypeValue) GTM.push({ pageType: pageTypeValue });
};

export const pushAirlineToGTM = (airlineToPush) => {
  GTM.push({ airline: airlineToPush });
};

export const pushLanguageToGTM = (language) => {
  GTM.push({ pageLanguage: language });
};

export const pushOperableSearchToGTM = (originAirport, destinationAirport) => {
  GTM.push({
    eventCategory : 'goal',
    eventAction   : 'operable search',
    eventLabel    : `${originAirport} - ${destinationAirport}`,
    operableSearch: 1,
    event         : 'gaEvent',
  });
};

/**
 * Send variable to GTM with service opportunities summary
 * @param {Array} eventLabel array with info of flights and products shown to user
 * We removed the previous implementation as will be requested on https://bagonboard.atlassian.net/browse/APS-3904
 */
export const pushServiceOpportunitiesToGTM = async (services) => {
  GTM.push({
    eventCategory     : 'goal',
    eventAction       : 'service opportunities',
    eventLabel        : services.join(' | '),
    serviceOpportunity: '1',
    event             : 'gaEvent',
  });
};
/**
 * Send Flight Search to GTM
 * Manual: 'manual - {{originAirport}} - {{destinationAirport}} - {{airline}} - {{date}}'
 * PNR: 'pnr - {{airline}}'
 * @param {Object} searchData
 * @param {String} searchData.method search method ('manual' or 'pnr')
 * @param {String} searchData.airline airline iata code
 * @param {String} searchData.from origin airport iata code (for manual only)
 * @param {String} searchData.to destination airport iata code (for manual only)
 * @param {Date} searchData.flightDate date of the flight (for manual only)
 */
export const pushFlightSearchToGTM = async ({ method, airline, ...data }) => {
  try {
    const eventLabel = `${method} - ${
      method === CONSTANTS.flightRetrieveMethod.PNR
        ? airline
        : `${data.from} - ${data.to} - ${airline} - ${ProviderDate.moment(data.flightDate).format('YYYYMMDD')}`}`;

    GTM.push({
      eventCategory: 'goal',
      eventAction  : 'flight search',
      eventLabel,
      flightSearch : '1',
      event        : 'gaEvent',
    });
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const pushPaymentMethodtoGTM = (paymentMethod) => {
  GTM.push({ paymentMethod });
};

export const pushIsIframeVariabletoGTM = () => {
  GTM.push({
    isIframe: true,
  });
};

export const pushBookingOriginToGTM = (type) => {
  GTM.push({
    bookingOrigin: type,
  });
};

export const pushRouteToGTM = (origin, destination, marketer, operator) => {
  GTM.push({ route: `${origin} - ${destination} - ${marketer} - ${operator}` });
};

export const pushPaymentErrorToGTM = (errorCode) => {
  GTM.push({
    eventCategory: 'payment_error',
    eventAction  : 'stripe',
    eventLabel   : errorCode,
    event        : 'gaEvent',
  });
};

export const pushNoAvailability = (reason, label) => {
  GTM.push({
    eventCategory: 'no availability',
    eventAction  : reason,
    eventLabel   : label,
    event        : 'gaEvent',
  });
};

export const pushFlightSelection = ({
  originAirport,
  destinationAirport,
  marketer,
  operator,
  date,
  destinationDate,
}) => {
  const hoursToDeparture = ProviderDate.getDifferenceOf(Date.now(), date, 'hours');
  GTM.push({
    eventCategory  : 'goal',
    eventAction    : 'flight selection',
    eventLabel     : `${originAirport} - ${destinationAirport} - ${marketer} - ${operator} - ${date} - ${destinationDate} - ${hoursToDeparture}`,
    flightSelection: '1',
    event          : 'gaEvent',
  });
};

export const pushAffiliatetoGTM = (affiliateToPush) => {
  GTM.push({ affiliate: affiliateToPush });
};
