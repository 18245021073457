import queryString from 'query-string';

import queryParamsPersist from '../queryParamsPersist';

const parseAndFilter = (search, filterParameters) => {
  const parameters = queryString.parse(search, { parseBooleans: true });
  return Object.entries(parameters)
    .filter(filterParameters)
    .reduce((all, [key, value]) => ({
      ...all,
      [key.toLowerCase()]: value,
    }), {});
};

const parseAndFilterParameters = (search, isIframe) => {
  const filterWidgetParameters = ([key]) => isIframe || !key.startsWith('widget');
  const paramsFromSearch = parseAndFilter(search, filterWidgetParameters);
  if (Object.keys(paramsFromSearch).length) {
    queryParamsPersist.save(paramsFromSearch);
  }
  return queryParamsPersist.get();
};

export default parseAndFilterParameters;
