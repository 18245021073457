// IMPORTANT: DONT'N DELETE THE COMMENTS OF THIS FILE
// **************************************************
import { push as routerPush } from 'connected-react-router';
import { SERVICE_TYPES, prop } from '@bagonboard/wiseman';
import { stringify } from 'query-string';
import moment from 'moment';

import * as bookingTypes from '../booking/types';
import * as dataTypes from './types';
import paths                          from '../../navigation/paths';
import axios                          from '../../lib/axios';
import { fetchNoParams, fetchFromS3 } from '../../lib/api';
import { fetchMultiFlightSchedule }   from '../../lib/schedule';
import { serviceSelectedFlightSelector, flightSelectedProductSelector } from '../booking/service/selectors';
import { isPnrRouter } from '../router';

const {
  CHECKIN, CITY_DELIVERY, SKIP_BAGGAGE_CLAIM, TRANSFER,
} = SERVICE_TYPES;

const instance = axios.getBackendInstance(60000);

const errors = {
  noResults         : 'No results from server.',
  unavailableSlot   : 'Unavailable slots for booking checkin.',
  unavailableService: 'Service unavailable',
};

export const errorEvent = (type, err) => ({ type, payload: { err } });

const formatPlainSlotReq = (date, booking, pickupDate) => {
  const {
    fromCode, toCode, marketer, type: service,
  } = booking;
  const cityCode = service === 'cityDelivery' ? toCode : fromCode;

  return {
    airline  : marketer,
    service,
    cityCode,
    limitDate: date,
    pickupDate,
  };
};

const getFlightCityObject = (obj) => {
  const {
    airportCode, date, terminalNumber, countryCode,
  } = obj;
  return {
    [airportCode]: {
      code   : airportCode,
      country: countryCode,
      date,
      terminalNumber,
    },
  };
};

const parseFlightConnectionResponse = (flight) => {
  const {
    origin,
    destination,
  } = flight;

  return {
    ...flight,
    ...getFlightCityObject(origin),
    ...getFlightCityObject(destination),
  };
};

/**
 *
 * @param data { flight, products }
 * @returns {*}
 */
const parseFlightsResults = (data, marketer) => data.map(({ flight, products }) => {
  const haveCheckin = products.some((product) => product.operable && product.service === CHECKIN);
  const haveSkipBaggageClaim = products.some(
    (product) => product.operable && product.service === SKIP_BAGGAGE_CLAIM
  );
  const shouldShowProduct = ({ service }) => {
    if (
      (service === TRANSFER && haveCheckin)
        || (service === CITY_DELIVERY && haveSkipBaggageClaim)
    ) {
      return false;
    }
    return true;
  };
  const parsedProducts = products.filter(shouldShowProduct);

  /**
   * The reason of injecting the marketer of service into pnr retrieve info is
   * that the marketer should be defined only on search airline or landing page.
   * Should be Iberia the source of truth of this value? The issue is that is not consistent.
   */

  const flightWithMarketerInjected = {
    ...flight,
    flightData: {
      ...flight.flightData,
      marketer,
    },
  };
  return {
    flight  : flightWithMarketerInjected,
    products: parsedProducts,
  };
});

const filterProductsToShow = (products) => {
  const haveCheckin = products.some((product) => (product.operable && product.service === CHECKIN));
  const haveSBC = products
    .some((product) => (product.operable && product.service === SKIP_BAGGAGE_CLAIM));
  return products.filter((product) => {
    if (
      (product.service === TRANSFER && haveCheckin)
      || (product.service === CITY_DELIVERY && haveSBC)
    ) {
      return false;
    }
    return true;
  });
};

export const showConnectionsAsFlights = (data) => data
  .map(({ flight, products = [] }) => ({
    flight,
    products: filterProductsToShow(products),
  }));

export const getFlightsData = (flightInfo = {}) => {
  const {
    flightNumbers = [],
    flight,
    origin: { date: originDate },
    destination: { date: destinationDate },
  } = flightInfo;

  // This is bc FS needs to know the correct date
  const isSameDay = moment(originDate).isSame(destinationDate, 'day');
  if (flightNumbers.length === 0) {
    return [{
      number: flight,
      date  : isSameDay ? originDate : destinationDate,
    }];
  }

  // WARN: We dont have the correct date of the intermediate flights. Is not neccessary until
  // we need logic for flights with stopovers bigger than a day.
  const result = flightNumbers.map((number, index) => {
    const isLast = (index === flightNumbers.length - 1);
    const dateResult = (isSameDay)
      ? (isLast) ? destinationDate : originDate
      : originDate;
    return {
      number,
      date: dateResult,
    };
  });
  return result;
};

/**
 * Too much magic here...
 * @param {Object} selectedFlight called when user selects a flight from the list of flights
 * @param {Object} product List of the products associated to the selected flight
 */
export function fetchFlight() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedFlight = serviceSelectedFlightSelector(state);
    const product = flightSelectedProductSelector(state);
    if (!product) {
      return dispatch(routerPush(paths.routerNoAvailability));
    }
    const { service } = product;
    try {
      const {
        airportSegments,
        marketer,
      } = selectedFlight.flightData;
      let scheduleFlight;
      // Selected product. No need to call it again in other places
      // All the flights selected are always related with products
      dispatch({
        type   : bookingTypes.SET_PRODUCT,
        payload: product.product,
      });
      // This will get extra inf but if fails could not be a stopper
      try {
        const fNumbers = getFlightsData(selectedFlight.flightData);
        const flightSchedule = await fetchMultiFlightSchedule(
          airportSegments, fNumbers
        ) || {};
        scheduleFlight = {
          ...parseFlightConnectionResponse(flightSchedule), // hace falta esto?
          marketer,
        };
      } catch (error) {
        // In case that whatever reason the request to fetchMultiFlightSchedule fails
        // we don't want to block the booking
        // Will appear a warning on BO to not have the terminal
      }

      dispatch({
        type   : dataTypes.FLIGHT_INFO_SUCCESS,
        payload: scheduleFlight,
      });

      // TODO: Check this dispatch
      dispatch({
        type   : bookingTypes.SET_BOOKING_PARAMETERS,
        payload: {
          fromCode: scheduleFlight.origin.airportCode,
          toCode  : scheduleFlight.destination.airportCode,
          type    : service,
        },
      });
    } catch (error) {
      const isPNR = isPnrRouter(state);
      dispatch(errorEvent(dataTypes.FLIGHT_INFO_ERROR, error));
      dispatch(errorEvent(dataTypes.TIME_SLOTS_PICKUP_ERROR, error));
      dispatch({ type: dataTypes.FLIGHT_INFO_END });
      dispatch(routerPush(isPNR ? paths.routerPnrNoResults : paths.routerNoAvailability));
      return undefined;
    }
    try {
      if (!product.pickupSlots && !product.deliverySlots) throw new Error(errors.unavailableSlot);
      const { flightClass, paxs } = selectedFlight;
      if (service === 'checkin' && Array.isArray(paxs) && paxs.length > 0) {
        dispatch({
          type   : bookingTypes.SET_CHECKIN_PAX_BAGS,
          payload: {
            paxs,
          },
        });
      }
      if (product.pickupSlots) {
        dispatch({
          type   : dataTypes.TIME_SLOTS_PICKUP_SUCCESS,
          payload: product.pickupSlots,
        });
      }
      if (product.deliverySlots) {
        dispatch({
          type   : dataTypes.TIME_SLOTS_DELIVERY_SUCCESS,
          payload: product.deliverySlots,
        });
      }
      dispatch({
        type   : bookingTypes.SET_CHECKIN_CLASS_TYPE,
        payload: flightClass,
      });
      let path;
      switch (service) {
        case CHECKIN:
          path = paths.routerCheckin;
          break;
        case CITY_DELIVERY:
          path = paths.routerDeliveryCity;
          break;
        case SKIP_BAGGAGE_CLAIM:
          path = paths.routerSkipBaggageClaim;
          break;
        default:
          path = paths.routerDeliveryAirport;
          break;
      }
      dispatch(routerPush(path));
    } catch (error) {
      dispatch(errorEvent(dataTypes.TIME_SLOTS_PICKUP_ERROR, error));
      dispatch({ type: dataTypes.FLIGHT_INFO_END });
    }
    return undefined;
  };
}

export function fetchPnrRetrieve(user, bookingCode, marketer, serviceType) {
  const urlPnr = '/pnr';

  return async (dispatch) => {
    try {
      if (!user || !bookingCode || !marketer) {
        return false;
      }
      dispatch({ type: dataTypes.FLIGHTS_REQUEST });
      dispatch({ type: dataTypes.FLIGHT_INFO_UPDATE, payload: { user, pnr: bookingCode } });
      const params = stringify({
        pnr: bookingCode,
        user,
        marketer,
        ...(serviceType ? { serviceType } : {}),
      });
      const response = await instance.get(`${urlPnr}?${params}`);
      const { status, data: { code, data } } = response;
      if (status !== 200 || code !== 200) {
        throw new Error(errors.unavailableService);
      }
      const flights = parseFlightsResults(data, marketer);
      dispatch({
        type   : dataTypes.FLIGHTS_SUCCESS,
        payload: flights,
      });
      return dispatch(routerPush(paths.routerPnrResults));
    } catch (error) {
      dispatch({ type: dataTypes.FLIGHT_INFO_UPDATE, payload: { user: null, pnr: null } });
      dispatch({
        type: dataTypes.FLIGHTS_ERROR,
      });
      return dispatch(routerPush(paths.routerPnrNoResults));
    }
  };
}

export function fetchTimeSlots(departure, pickup) {
  const urlSlots = '/area/v2-slots';
  return async (dispatch, getState) => {
    try {
      if (pickup) dispatch({ type: dataTypes.TIME_SLOTS_DELIVERY_REQUEST });
      else dispatch({ type: dataTypes.TIME_SLOTS_PICKUP_REQUEST });
      const { service } = getState().booking;
      const params = formatPlainSlotReq(departure, service, pickup);
      const { data: { code, data } } = await instance.get(urlSlots, { params });

      const { slotsAvailable } = data;
      if (code === 200) {
        dispatch({
          type: pickup
            ? dataTypes.TIME_SLOTS_DELIVERY_SUCCESS
            : dataTypes.TIME_SLOTS_PICKUP_SUCCESS,
          payload: data,
        });
      }
      if (code !== 200 || !slotsAvailable) throw new Error(errors.unavailableSlot);
      return true;
    } catch (error) {
      dispatch(
        errorEvent(pickup
          ? dataTypes.TIME_SLOTS_DELIVERY_ERROR
          : dataTypes.TIME_SLOTS_PICKUP_ERROR, error)
      );
      return false;
    }
  };
}

export function cleanFlightErrors() {
  return { type: dataTypes.FLIGHT_INFO_CLEAN };
}

export function fetchConnectionsFlight(flightParams) {
  const urlSlots = '/flight/products/operable/connectionflights';
  return async (dispatch) => {
    const {
      fromCode: from,
      toCode: to,
      marketer: airline, // TODO Remove airline concept.
      departureDate: date,
      connections,
      serviceType,
    } = flightParams;
    try {
      if (!from || !to || !airline || !date || !connections) return false;
      dispatch({ type: dataTypes.FLIGHTS_REQUEST });
      const response = await instance.post(urlSlots, {
        from, to, airline, date, connections, serviceType,
      });
      const { status, data: { code, data } } = response;
      if (status !== 200 || code !== 200) throw new Error(errors.unavailableService);

      const flights = showConnectionsAsFlights(data);
      // TODO: Refactor: we can just return the data and have it on the state of the component?
      // is that possible to navigate, and come back again to the view and still having
      // this info with the hooks, right?
      dispatch({
        type   : dataTypes.FLIGHTS_SUCCESS,
        payload: flights,
      });
      if (flights.length === 0) return dispatch(routerPush(paths.routerFromToDateNoResults));
      return dispatch(routerPush(paths.routerFromToDateResults));
    } catch (error) {
      return dispatch({
        type: dataTypes.FLIGHTS_ERROR,
      });
    }
  };
}

export function fetchIATA(serviceId) {
  const urlIATA = '/order/service/iata';
  return async (dispatch) => {
    try {
      dispatch({ type: dataTypes.IATA_REQUEST });
      const urlWithParams = `${urlIATA}/${serviceId}`;
      const response = await instance.get(urlWithParams);
      const { status, data: { code, data } } = response;
      if (status !== 200 || code !== 200) throw new Error(errors.unavailableService);
      dispatch({
        type   : dataTypes.IATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: dataTypes.IATA_ERROR,
      });
    }
  };
}

let requested = false;
export function fetchFeaturedAirports(locale) {
  const urlAirports = '/airports';
  return async (dispatch) => {
    try {
      if (!requested) {
        requested = true;
        dispatch({ type: dataTypes.AIRPORTS_LIST_REQUEST });
        const urlWithParams = `${urlAirports}/?locale=${locale}`;
        const response = await instance.get(urlWithParams);
        const { status, data: { code, data } } = response;
        if (status !== 200 || code !== 200) throw new Error(errors.unavailableService);
        dispatch({
          type   : dataTypes.AIRPORTS_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      requested = false;
      dispatch({
        type: dataTypes.AIRPORTS_LIST_ERROR,
      });
    }
  };
}

export const buildFeaturedAirlineItem = (featuredAirline) => {
  const title = `${featuredAirline.name} (${featuredAirline.code})`;
  return {
    ...featuredAirline,
    title,
    value   : title,
    featured: true,
  };
};

export function fetchAirlines() {
  const urlAirport = '/company/airline';
  return async (dispatch) => {
    try {
      dispatch({ type: dataTypes.AIRLINES_LIST_REQUEST });
      const [
        featuredAirlines = [],
        airlines = [],
      ] = await Promise.all([
        fetchNoParams(urlAirport),
        fetchFromS3('airlines.min.json'),
      ]);
      const result = featuredAirlines
        .map(buildFeaturedAirlineItem);
      const featuredCodes = featuredAirlines.map(prop('code'));
      dispatch({
        type   : dataTypes.AIRLINES_LIST_SUCCESS,
        payload: result.concat(airlines.filter(({ code }) => !featuredCodes.includes(code))),
      });
    } catch (error) {
      dispatch({
        type: dataTypes.AIRLINES_LIST_ERROR,
      });
    }
  };
}

export function setFlightInfo(info) {
  return {
    type   : dataTypes.FLIGHT_INFO_SUCCESS,
    payload: info,
  };
}

export const testActions = {
  formatPlainSlotReq,
  parseFlightConnectionResponse,
  parseFlightsResults,
  showConnectionsAsFlights,
};
