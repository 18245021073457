import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import {
  Header, ICONS, Icon, palette,
} from '@bagonboard/ui-kit';
import {
  BackContainer, CloseIcon, LogoContainer, BackIcon,
} from './styles';
import { homePageRedirect } from '../../lib/redirections';
import { sendMessageTo } from '../../lib/widget';

import paths from '../../navigation/paths';
import { isIframeSelector } from '../../reducers/widget';

const DISABLED_BACK_PATHS = [
  paths.routerNoPartner,
  paths.routerPnr,
  paths.routerFromToDate,
  paths.confirmationCheckin,
  paths.confirmationSkipBaggageClaim,
  paths.confirmationDeliveryAirport,
  paths.confirmationDeliveryCity,
  paths.payment,
  paths.biometric,
  paths.pirInfo.auth,
];

const DISABLED_BACK_PATHS_ON_IFRAME = [
  ...DISABLED_BACK_PATHS,
  paths.paymentError,
];

const TYPES = {
  isIframe: PropTypes.bool,
  marketer: PropTypes.string,
  icon    : PropTypes.string,
  iconSize: PropTypes.number,
};

const BackButton = ({ onBack, isIframe }) => {
  const history = useHistory();
  const location = useLocation();

  const COMPUTED_DISABLED_BACK_PATHS = isIframe
    ? DISABLED_BACK_PATHS_ON_IFRAME
    : DISABLED_BACK_PATHS;

  const showBack =    !COMPUTED_DISABLED_BACK_PATHS.includes(location.pathname)
    && history.length > 2;

  if (!showBack) {
    return null;
  }

  return (
    <BackContainer onClick={onBack || history.goBack} id="bob-back-header">
      <BackIcon icon={ICONS.LEFT} size={14} />
      <Translate value="Booking.common.back" />
    </BackContainer>
  );
};

BackButton.propTypes = {
  onBack  : PropTypes.func,
  isIframe: PropTypes.bool,
};

BackButton.defaultProps = {
  onBack  : undefined,
  isIframe: false,
};

const CloseButton = ({ isIframe }) => (isIframe
  ? (
    <div id="widget-fix-no-header-margin">
      <CloseIcon
        size={24}
        icon={ICONS.CLOSE}
        testId="bob-widget-close"
        handleClick={() => sendMessageTo('bobWidgetClose')}
      />
    </div>
  )
  : null);

CloseButton.propTypes = {
  isIframe: TYPES.isIframe.isRequired,
};

const Logo = ({
  isIframe, marketer, icon, iconSize,
}) => (isIframe
  ? null
  : (
    <LogoContainer onClick={() => homePageRedirect(marketer)}>
      <Icon
        icon={icon}
        size={iconSize}
        color={palette.primary}
        colorHover={palette.primary}
      />
    </LogoContainer>
  ));

Logo.defaultProps = {
  marketer: undefined,
};

Logo.propTypes = {
  isIframe: TYPES.isIframe.isRequired,
  marketer: TYPES.marketer,
  icon    : TYPES.icon.isRequired,
  iconSize: TYPES.iconSize.isRequired,
};

export const CustomHeader = ({
  icon, iconSize, marketer, onBack,
}) => {
  const isIframe = useSelector(isIframeSelector);
  return (
    <Header
      leftComponent={<BackButton onBack={onBack} isIframe={isIframe} />}
      centerComponent={(
        <Logo
          isIframe={isIframe}
          marketer={marketer}
          icon={ICONS[icon]}
          iconSize={iconSize}
        />
      )}
      rightComponent={<CloseButton isIframe={isIframe} />}
      maxWidth="1210px"
    />
  );
};

CustomHeader.propTypes = {
  icon    : PropTypes.string,
  iconSize: PropTypes.number,
  marketer: PropTypes.string,
  onBack  : PropTypes.func,
};

CustomHeader.defaultProps = {
  icon    : 'BOB',
  iconSize: 58,
  marketer: undefined,
  onBack  : undefined,
};

export default CustomHeader;
