export default {
  routerNoPartner                 : '/',
  routerPnr                       : '/router/pnr',
  routerFromToDate                : '/router/from-to-date',
  routerPnrResults                : '/router/pnr/results',
  routerPnrNoResults              : '/router/pnr/no-results',
  routerFromToDateResults         : '/router/from-to-date/results',
  routerFromToDateNoResults       : '/router/from-to-date/no-results',
  routerServices                  : '/router/services',
  routerNoAvailability            : '/router/no-availability',
  routerCheckin                   : '/router/checkin',
  routerDeliveryAirport           : '/router/delivery/airport',
  routerDeliveryCity              : '/router/delivery/city',
  routerSkipBaggageClaim          : '/router/skipbaggageclaim',
  steps                           : '/steps',
  stepsBags                       : '/bags',
  stepsBagsPaxAllowance           : '/bags/pax-allowance',
  stepsBagsPaxNoAllowance         : '/bags/pax-no-allowance',
  stepsBagsNoPax                  : '/bags/no-pax',
  stepsPickupAddress              : '/pickup-address',
  stepsPickupAddressOutOfService  : '/pickup-address/out-of-service',
  stepsPickupTime                 : '/pickup-time',
  stepsDeliveryAddress            : '/delivery-address',
  stepsDeliveryAddressOutOfService: '/delivery-address/out-of-service',
  stepsDeliveryTime               : '/delivery-time',
  stepsContactInfo                : '/contact-info',
  stepsContactInfoCustomerKnown   : '/contact-info/customer-known',
  stepsContactInfoCustomerUnknown : '/contact-info/customer-unknown',
  stepsPayment                    : '/payment',
  paymentError                    : '/payment-error',
  confirmationCheckin             : '/confirmation/checkin',
  confirmationDeliveryAirport     : '/confirmation/delivery/airport',
  confirmationDeliveryCity        : '/confirmation/delivery/city',
  confirmationSkipBaggageClaim    : '/confirmation/skipbaggageclaim',
  serviceSelector                 : {
    flights           : '/flights',
    flightConfirmation: '/flight-confirmation',
  },
  iata     : '/iata',
  payment  : '/payment',
  biometric: '/biometric',
  pirInfo  : {
    auth   : '/pir-info',
    summary: '/pir-info/summary',
  },
  cancellation: '/cancellation',
};
