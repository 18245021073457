import { combineReducers } from 'redux';
import * as types from './types';

export const flightInfo = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.FLIGHT_INFO_REQUEST:
      return {
        ...state,
        isLoading  : true,
        flightError: false,
      };
    case types.FLIGHT_INFO_SUCCESS:
      return {
        ...state,
        isLoading : false,
        flightData: action.payload,
      };
    case types.FLIGHT_INFO_ERROR:
      return {
        ...state,
        isLoading  : false,
        flightError: true,
      };
    case types.FLIGHT_INFO_END:
      return {
        ...state,
        isLoading: false,
      };
    case types.FLIGHT_INFO_CLEAN:
      return {
        ...state,
        isLoading  : false,
        flightError: false,
      };
    case types.FLIGHT_INFO_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const timeSlots = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.TIME_SLOTS_PICKUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        slotError: false,
      };
    case types.TIME_SLOTS_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading  : false,
        pickupSlots: action.payload,
      };
    case types.TIME_SLOTS_PICKUP_ERROR:
      return {
        ...state,
        isLoading: false,
        slotError: true,
      };
    case types.TIME_SLOTS_DELIVERY_REQUEST:
      return {
        ...state,
        isLoading: true,
        slotError: false,
      };
    case types.TIME_SLOTS_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading    : false,
        slotsDelivery: action.payload,
      };
    case types.TIME_SLOTS_DELIVERY_ERROR:
      return {
        ...state,
        isLoading: false,
        slotError: true,
      };
    case types.FLIGHT_INFO_END:
      return {
        ...state,
        isLoading: false,
      };
    case types.FLIGHT_INFO_CLEAN:
      return {
        ...state,
        isLoading: false,
        slotError: false,
      };
    default:
      return state;
  }
};

export const flights = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.FLIGHTS_REQUEST:
      return {
        ...state,
        data        : [],
        isLoading   : true,
        flightsError: false,
      };
    case types.FLIGHTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data     : action.payload,
      };
    case types.FLIGHTS_ERROR:
      return {
        ...state,
        isLoading   : false,
        flightsError: true,
      };
    default:
      return state;
  }
};

export const terminal = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.TERMINAL_NUMBER_REQUEST:
      return {
        ...state,
        isLoading    : true,
        terminalError: false,
      };
    case types.TERMINAL_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading     : false,
        terminalNumber: action.payload,
      };
    case types.TERMINAL_NUMBER_ERROR:
      return {
        ...state,
        isLoading    : false,
        terminalError: true,
      };
    case types.TERMINALS_REQUEST:
      return {
        ...state,
        isLoading    : true,
        terminalError: false,
      };
    case types.TERMINALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case types.TERMINALS_ERROR:
      return {
        ...state,
        isLoading    : false,
        terminalError: true,
      };
    default:
      return state;
  }
};

export const airports = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.POI_AIRPORT_REQUEST:
      return {
        ...state,
        isLoading      : true,
        poiAirportError: false,
      };
    case types.POI_AIRPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case types.POI_AIRPORT_ERROR:
      return {
        ...state,
        isLoading      : false,
        poiAirportError: true,
      };
    case types.AIRPORTS_LIST_REQUEST:
      return {
        ...state,
        isLoading        : true,
        airportsListError: false,
      };
    case types.AIRPORTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading   : false,
        airportsList: action.payload,
      };
    case types.AIRPORTS_LIST_ERROR:
      return {
        ...state,
        isLoading        : false,
        airportsListError: true,
      };
    default:
      return state;
  }
};

export const area = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.AREA_REQUEST:
      return {
        ...state,
        isLoading: true,
        areaError: false,
      };
    case types.AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case types.AREA_ERROR:
      return {
        ...state,
        isLoading: false,
        areaError: true,
      };
    default:
      return state;
  }
};

export const price = (
  state = {
    isLoading: false,
    products : [],
  },
  action
) => {
  switch (action.type) {
    case types.PRICE_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading         : true,
        priceProductsError: false,
      };
    case types.PRICE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products : action.payload || [],
      };
    case types.PRICE_PRODUCT_ERROR:
      return {
        ...state,
        isLoading         : false,
        priceProductsError: true,
      };
    default:
      return state;
  }
};

export const promo = (
  state = {
    editing  : false,
    isLoading: false,
    data     : {},
    error    : false,
    value    : '',
  },
  action
) => {
  switch (action.type) {
    case types.CHECK_PROMOCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error    : false,
      };
    case types.CHECK_PROMOCODE_SET:
      return {
        ...state,
        editing: true,
        value  : action.data,
        error  : false,
      };
    case types.CHECK_PROMOCODE_SET_EDITING:
      return {
        ...state,
        editing: action.data,
      };
    case types.CHECK_PROMOCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data     : action.payload || {},
      };
    case types.CHECK_PROMOCODE_ERROR:
      return {
        ...state,
        isLoading: false,
        error    : true,
      };
    case types.CHECK_PROMOCODE_CLEAN:
      return {
        isLoading: false,
        data     : {},
        error    : false,
        value    : '',
      };
    default:
      return state;
  }
};

export const iata = (
  state = {
    iataLoading: false,
    data       : undefined,
  },
  action
) => {
  switch (action.type) {
    case types.IATA_REQUEST:
      return {
        ...state,
        iataLoading: true,
        iataError  : false,
      };
    case types.IATA_SUCCESS:
      return {
        ...state,
        iataLoading: false,
        data       : action.payload,
      };
    case types.IATA_ERROR:
      return {
        ...state,
        iataLoading: false,
        iataError  : true,
      };
    default:
      return state;
  }
};

export const airlines = (
  state = {
    airlinesLoading: false,
  },
  action
) => {
  switch (action.type) {
    case types.AIRLINES_LIST_REQUEST:
      return {
        ...state,
        airlinesLoading: true,
        airlinesError  : false,
      };
    case types.AIRLINES_LIST_SUCCESS:
      return {
        ...state,
        airlinesLoading: false,
        airlinesError  : false,
        airlinesList   : action.payload,
      };
    case types.AIRLINES_LIST_ERROR:
      return {
        ...state,
        airlinesLoading: false,
        airlinesError  : true,
      };
    default:
      return state;
  }
};

const flightInfoReducer = combineReducers({
  airlines,
  airports,
  area,
  flightInfo,
  flights,
  iata,
  price,
  promo,
  terminal,
  timeSlots,
});

export default flightInfoReducer;
