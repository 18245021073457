import React from 'react';
import styled from 'styled-components/macro';
import { MobileCta } from '@bagonboard/ui-kit';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';

import { widths, font, mediaQueries } from '../styles/widths';
import palette from '../styles/palette';

import { ConnectedCustomHeader, ErrorBoundary } from '../components';
import { ButtonDesktop } from '../lib/ButtonDesktop';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  padding: 0 ${widths.base * 2}px;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 500px;
  max-width: 100%;
`;

const Img = styled.img.attrs({
  alt: '',
})`
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${mediaQueries.desktop} {
    max-width: 100%;
    width: 280px;
  }
  @media(max-height: 600px) {
    max-height: 30vh;
  }
`;

const Title = styled.h1`
  color      : ${palette.bobBlack};
  font-size  : 24px;
  font-weight: ${font.semi};
  line-height: 1.4;
  margin-top : ${widths.base * 4}px;
  margin-bottom: 0;
  text-align : center;
  ${mediaQueries.desktop} {
    font-size  : 32px;
  }
  @media(max-height: 600px) {
    margin-top : ${widths.base * 3}px;
  }
`;

const Lead = styled.h2`
  font-size: 20px;
  font-weight: ${font.semi};
  line-height: 1.2;
  color      : ${palette.bobBlack};
  margin-top : ${widths.base * 2}px;
  ${mediaQueries.desktop} {
    margin-top : ${widths.base * 3}px;
  }
`;

const Message = styled.p`
  font-size: 14px;
  font-weight: ${font.medium};
  line-height: 1.5;
  text-align: center;
  ${mediaQueries.desktop} {
    font-size  : 16px;
  }
`;

const backToHome = () => {
  // eslint-disable-next-line no-restricted-globals
  location.href = process.env.REACT_APP_HOMEPAGE;
};

const ErrorComponent = () => (
  <>
    <ConnectedCustomHeader />
    <Container>
      <Content>
        <div>
          <Img src="https://bob.io/wp-content/uploads/200-1.svg" />
        </div>
        <Title>
          <Translate value="ErrorBoundary.title" />
        </Title>
        <Lead>
          <Translate value="ErrorBoundary.lead" />
        </Lead>
        <Message>
          <Translate value="ErrorBoundary.message" />
        </Message>
        <div>
          <ButtonDesktop
            handleClick={backToHome}
            text={I18n.t('ErrorBoundary.cta')}
            width={264}
          />
        </div>
      </Content>
    </Container>
    <MobileCta
      handleClick={backToHome}
      text={I18n.t('ErrorBoundary.cta')}
    />
  </>
);

const AppError = ({ children }) => (
  <ErrorBoundary ErrorComponent={ErrorComponent}>
    {children}
  </ErrorBoundary>
);

AppError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppError;
