import { createSelector } from 'reselect';
import { propOr } from '@bagonboard/wiseman';

export const flightsSelector = (state) => state.data.flights;

const EMPTY_ARR = [];
export const flightsDataSelector = createSelector(
  flightsSelector,
  propOr(EMPTY_ARR, 'data'),
);

export const flightsIsLoadingSelector = createSelector(
  flightsSelector,
  propOr(false, 'isLoading'),
);
