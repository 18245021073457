import { SET_PARAMETERS, MERGE_PARAMETERS } from './types';

const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_PARAMETERS:
      return action.payload;
    case MERGE_PARAMETERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
