import { createBrowserHistory, createMemoryHistory } from 'history';

const isInIframe = window.self !== window.parent;

const options = {
  basename: process.env.REACT_APP_BASENAME,
};

const history = isInIframe
  ? createMemoryHistory({
    ...options,
    initialEntries: [`/${window.location.search}`],
  })
  : createBrowserHistory(options);

export default history;
