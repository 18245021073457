import { createSelector } from 'reselect';
import { propEq } from 'ramda';
import {
  defaultTo, pipe, prop, propOr, getPaxsTotalBags,
  SERVICE_TYPES,
} from '@bagonboard/wiseman';
// TODO: check if wiseman has this function
import { betweenParenthesisRegex } from '../../../../statics/validations/regex';

const { SKIP_BAGGAGE_CLAIM } = SERVICE_TYPES;

export const serviceSelector = (state) => state.booking.service;

const makeServicePropSelector = (key) => pipe(serviceSelector, prop(key));

export const serviceIsFreeSelector = pipe(
  serviceSelector,
  propOr(false, 'isFree')
);

export const serviceIsSBC = pipe(
  serviceSelector,
  propEq('type', SKIP_BAGGAGE_CLAIM)
);

export const servicePaxsSelector = makeServicePropSelector('paxs');

export const serviceTotalBagsSelector = createSelector(
  servicePaxsSelector,
  getPaxsTotalBags
);

export const serviceMarketerSelector = makeServicePropSelector('marketer');
export const serviceOperatorSelector = makeServicePropSelector('operator');
export const serviceAirlineSelector = makeServicePropSelector('airline');
export const serviceTypeSelector = makeServicePropSelector('type');
export const serviceProductSelector = makeServicePropSelector('product');
export const serviceDeliverySlotSelector = makeServicePropSelector('deliverySlot');
export const serviceDeliveryTimeSelector = makeServicePropSelector('deliveryTime');
export const servicePickupSlotSelector = makeServicePropSelector('pickupSlot');
export const servicePickupTimeSelector = makeServicePropSelector('pickupTime');
export const serviceFromCodeSelector = makeServicePropSelector('fromCode');
export const serviceToCodeSelector = makeServicePropSelector('toCode');
export const serviceBiometricSelector = makeServicePropSelector('biometric');
export const serviceIsLoadingSelector = makeServicePropSelector('isLoading');
export const serviceFromToCodeSelector = createSelector(
  serviceFromCodeSelector,
  serviceToCodeSelector,
  (fromCode, toCode) => ({ fromCode, toCode })
);

export const serviceDepartureDateSelector = makeServicePropSelector('departureDate');
export const serviceBagCommentSelector = makeServicePropSelector('bagComment');
export const serviceSelectedFlightKeysSelector = makeServicePropSelector(
  'selectedFlightKeys'
);
export const serviceSelectedProductIdSelector = makeServicePropSelector(
  'selectedProduct'
);

const getProperAirlineReplacement = (airline) => {
  const result = betweenParenthesisRegex.exec(airline);
  return result ? result[0] : '';
};

export const serviceTrimmedAirlineSelector = createSelector(
  serviceAirlineSelector,
  pipe(defaultTo(''), (airline) => airline.replace(getProperAirlineReplacement(airline), '').trim())
);
