export const widths = {
  /* Layout variables */
  desktopWidth         : 1024,
  footerHeight         : 200,
  headerHeight         : 56,
  containerWidth       : 696,
  bigContainerWidth    : 1176,
  column               : 96,
  gutter               : 24,
  base                 : 8,
  bookingContainerWidth: 936,
  tinyMobile           : 350,
};

export const font = {
  regular: 400,
  medium : 500,
  semi   : 600,
  montse : 'Montserrat, Helvetica, Arial, sans-serif',
};

export const mediaQueries = {
  desktop   : `@media (min-width: ${widths.desktopWidth}px)`,
  notDesktop: `@media not all and (min-width: ${widths.desktopWidth}px)`,
  tinyMobile: `@media (max-width: ${widths.tinyMobile}px)`,
};

export const imageQueries = {
  desktop: `(min-width: ${widths.desktopWidth}px)`,
};
