import { useLayoutEffect } from 'react';

import { hideLoading } from '../lib/loading';

export const callFnWhenNeeded = (isValidUrl, fn) => {
  if (isValidUrl) {
    fn();
  }
};

const useConnectedHideLoading = (isValidUrl) => {
  useLayoutEffect(() => {
    callFnWhenNeeded(isValidUrl, hideLoading);
  }, [isValidUrl]);
};

export default useConnectedHideLoading;
