import * as types from '../types';
import CONSTANTS from '../../../lib/constants';

const { paymentMethods: { STRIPE } } = CONSTANTS;

const initialState = {
  isLoading      : false,
  error          : undefined,
  orderBooked    : undefined,
  requiresAction : false,
  stripePaymentId: undefined,
  paymentMethod  : STRIPE,
};

export const orderReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.ORDER_REQUEST:
    case types.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        isLoading     : true,
        error         : false,
        requiresAction: false,
      };
    case types.ORDER_REQUEST_PAYMENT_REQUIRES_ACTION:
      return {
        ...state,
        isLoading     : false,
        orderBooked   : action.payload || {},
        requiresAction: true,
      };
    case types.ORDER_REQUEST_SUCCESS:
    case types.UPDATE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading  : false,
        orderBooked: action.payload || {},
      };

    case types.ORDER_REQUEST_ERROR:
    case types.UPDATE_ORDER_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error    : action.payload,
      };
    case types.SET_REFERRAL:
      return {
        ...state,
        referral: action.payload,
      };
    case types.SET_STRIPE_PAYMENT_ID:
      return {
        ...state,
        stripePaymentId: action.payload,
      };
    case types.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case types.CANCEL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error    : false,
      };
    case types.CANCEL_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading     : false,
        orderCancelled: action.payload || {},
      };
    case types.CANCEL_ORDER_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error    : action.error,
      };
    default:
      return state;
  }
};

export default orderReducer;
