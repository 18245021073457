import { ProviderDate } from '@bagonboard/ui-kit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { SERVICE_TYPES, isDestinationService, ORDER_STATUS } from '@bagonboard/wiseman';
import { routerPush } from '../../../lib/redirections';
import {
  fetchNoParams,
  fetchPost,
  fetchPostNoValidations,
  errors,
} from '../../../lib/api';
import paths from '../../../navigation/paths';

import * as typesData from '../../data/types';
import * as types from '../types';

import { ENDPOINTS } from './constants';
import { serviceSelectedFlightSelector, flightDataSelector } from '../service/selectors';

const { AWAITING, OPEN, CONFIRMED } = ORDER_STATUS;

const { CHECKIN, CITY_DELIVERY, SKIP_BAGGAGE_CLAIM } = SERVICE_TYPES;

export function getBags(paxs = []) {
  return paxs.reduce((total, pax) => total + (pax.numBags || 0), 0);
}

const plainFlightInfo = ({ flightData, ...rest }) => ({
  ...rest,
  ...flightData,
  origin: {
    ...flightData.origin,
    terminal: {
      number: flightData.origin.terminalNumber,
    },
  },
  destination: {
    ...flightData.destination,
    terminal: {
      number: flightData.destination.terminalNumber,
    },
  },
});

function getServices({
  address, airports, service, flight,
}) {
  const {
    affiliate,
    bagComment,
    deliverySlot,
    deliveryTime,
    fromCode,
    toCode,
    isFree,
    marketer,
    operator,
    paxs,
    pickupSlot,
    pickupTime,
    timezone,
    type,
    typeCheckin,
  } = service;
  const { flightData } = flight;

  const code = isDestinationService(type) ? toCode : fromCode;
  // eslint-disable-next-line no-underscore-dangle
  const airportPoiAddress = { poi: airports[code]._id };
  // TODO: This logic of send company param has to disapear from here
  // and control it on backend order creation
  const company = (CHECKIN === type)
    ? flightData.operator || flightData.airline
    : 'BOB';

  const service1 = {
    type,
    company,
    tier  : isFree ? typeCheckin : 'standard',
    from  : isDestinationService(type) ? airportPoiAddress : address,
    to    : isDestinationService(type) ? address : airportPoiAddress,
    ...(pickupTime && { pickupTime: ProviderDate.utcTimezone(pickupTime, timezone) }),
    ...(deliveryTime && { deliveryTime: ProviderDate.utcTimezone(deliveryTime, timezone) }),
    pickupSlot,
    deliverySlot,
    paxs,
    bags  : getBags(paxs),
    bagComment,
    vendor: {
      affiliate,
      marketer,
      operator,
    },
    flightInfo: plainFlightInfo(flight),
  };

  return [service1];
}

/**
 * @param {Object} state Redux state
 */
function getBody(state) {
  const {
    booking: {
      service,
      address,
      customer,
      order: { referral, stripePaymentId, paymentMethod },
    },
    data: {
      airports,
      promo: {
        data: { isValid, code },
      },
    },
    widget: { isIframe },
  } = state;

  const { isFree, landing } = service;

  const sourceFlight = serviceSelectedFlightSelector(state);
  const flightData = flightDataSelector(state);

  const flight = {
    ...sourceFlight,
    flightData,
  };

  const services = getServices({
    address,
    airports,
    service,
    flight,
  });

  const finalCustomer = {
    ...customer,
    phone  : customer.phone,
    surname: customer.surname || '',
  };

  const promo = isValid && code ? code : undefined;

  const body = {
    services,
    customer: finalCustomer,
    ...((!isIframe || isFree) && {
      payment: { token: stripePaymentId, method: paymentMethod },
    }),
    promo        : isFree ? 'IBBUSINESS' : promo,
    bookingOrigin: {
      url: landing,
    },
  };

  if (referral) body.referral = referral;

  return body;
}

async function sendOrderBooking(state) {
  const body = getBody(state);
  return fetchPost(ENDPOINTS.orderPath, body);
}

async function getPaymentIntent(getState) {
  const { booking: { order: { orderBooked: { _id: orderId }, stripePaymentId } } } = getState();
  const body = { orderId, paymentMethodToken: stripePaymentId };
  return fetchPost(ENDPOINTS.getPaymentIntent, body);
}

async function updateOrderBooking(orderId, paymentToken) {
  const body = {
    orderId,
    payment: paymentToken,
  };
  return fetchPost(ENDPOINTS.confirmPayment, body);
}

function redirectToConfirmation(dispatch, state, status) {
  const {
    booking: {
      service: { type },
    },
  } = state;
  if (status && status === AWAITING) return dispatch(routerPush(paths.paymentError));
  let path;
  switch (type) {
    case CHECKIN:
      path = paths.confirmationCheckin;
      break;
    case SKIP_BAGGAGE_CLAIM:
      path = paths.confirmationSkipBaggageClaim;
      break;
    case CITY_DELIVERY:
      path = paths.confirmationDeliveryCity;
      break;
    default:
      path = paths.confirmationDeliveryAirport;
      break;
  }
  return dispatch(routerPush(path));
}

export async function sendOrder(dispatch, getState) {
  // TODO -> FETCH FROM MARKETER WHEN WE HAVE MORE THAN 1 AIRLINE
  try {
    dispatch({ type: types.ORDER_REQUEST });
    const state = getState();
    const data = await sendOrderBooking(state);

    const {
      _id, code, error, message, payment, status,
    } = data;
    if (error) {
      return dispatch({
        type   : types.ORDER_REQUEST_ERROR,
        payload: { code, message },
      });
    }
    if (!_id) throw new Error(errors.noResults);
    if (status === AWAITING && payment && payment.clientSecret) {
      return dispatch({
        type   : types.ORDER_REQUEST_PAYMENT_REQUIRES_ACTION,
        payload: data,
      });
    }
    dispatch({
      type   : types.ORDER_REQUEST_SUCCESS,
      payload: data,
    });
    return redirectToConfirmation(dispatch, state, status);
  } catch (error) {
    return dispatch({
      type   : types.ORDER_REQUEST_ERROR,
      payload: { code: 'generic_error', message: error.message },
    });
  }
}

export async function sendPaymentIntent(dispatch, getState) {
  try {
    dispatch({ type: types.ORDER_REQUEST });
    const state = getState();
    const data = await getPaymentIntent(getState);
    const { orderId, paymentIntent } = data;

    if (!orderId) throw new Error(errors.noResults);
    if (paymentIntent.clientSecret) {
      const payload = {
        ...state.booking.order.orderBooked,
        _id    : orderId,
        payment: {
          id          : paymentIntent.id,
          clientSecret: paymentIntent.clientSecret,
        },
      };
      return await dispatch({
        type: types.ORDER_REQUEST_PAYMENT_REQUIRES_ACTION,
        payload,
      });
    }
    const order = state.booking.order.orderBooked || {};
    dispatch({
      type   : types.ORDER_REQUEST_SUCCESS,
      payload: {
        ...order,
        status: CONFIRMED,
      },
    });
    return redirectToConfirmation(dispatch, state, CONFIRMED);
  } catch (error) {
    return dispatch({
      type   : types.ORDER_REQUEST_ERROR,
      payload: { code: 'generic_error', message: error.message },
    });
  }
}

export function updateBooking(orderId, paymentId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.UPDATE_ORDER_REQUEST });
      const data = await updateOrderBooking(orderId, paymentId);
      const {
        code, error, message, status,
      } = data;
      if (error) {
        return dispatch({
          type   : types.UPDATE_ORDER_REQUEST_ERROR,
          payload: { code, message },
        });
      }

      dispatch({
        type   : types.UPDATE_ORDER_REQUEST_SUCCESS,
        payload: data,
      });
      const state = getState();
      return redirectToConfirmation(dispatch, state, status);
    } catch (error) {
      // TODO: Refactor common code
      return dispatch({
        type   : types.UPDATE_ORDER_REQUEST_ERROR,
        payload: { code: 'generic_error', message: error.message },
      });
    }
  };
}

export function stripePaymentCreationError(error) {
  const { message } = error;
  return {
    type   : types.ORDER_REQUEST_ERROR,
    payload: { code: 'generic_error', message },
  };
}

export function stripeHandleRequiredActionError() {
  return (dispatch, getState) => {
    const state = getState();
    return redirectToConfirmation(dispatch, state);
  };
}

export function orderBooking(payDirectly) {
  return async (dispatch, getState) => {
    if (payDirectly) {
      await sendPaymentIntent(dispatch, getState);
    } else {
      await sendOrder(dispatch, getState);
    }
  };
}

export function getOrderById(id, token) {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: types.ORDER_REQUEST });
      const result = await fetchNoParams(`${ENDPOINTS.getOrder}${id}?expand=promo&expand=services${token ? `&token=${token}` : ''}`);
      const data = {
        bookingCode  : result.bookingCode,
        bookingOrigin: result.bookingOrigin?.platform,
        customer     : result.customer,
        _id          : result._id, // eslint-disable-line
      };
      await dispatch({
        type   : types.ORDER_REQUEST_SUCCESS,
        payload: data,
      });
      if (result.services && result.services.length) {
        const address = isDestinationService(result.services[0].type)
          ? result.services[0].to
          : result.services[0].from;
        await dispatch({
          type   : types.SET_BOOKING_PARAMETERS,
          payload: result.services[0],
        });
        await dispatch({
          type   : types.SET_ADDRESS,
          payload: address,
        });
        await dispatch({
          type   : typesData.FLIGHT_INFO_SUCCESS,
          payload: result.services[0].flightInfo,
        });
      }
      if (result.customer) {
        await dispatch({
          type   : types.SET_CUSTOMER,
          payload: result.customer,
        });
      }
      const [{ products }] = result.invoices;
      await dispatch({
        type   : typesData.PRICE_PRODUCT_SUCCESS,
        payload: products,
      });
      if (result.promo && result.promo.code) {
        const promo = {
          amount : result.invoices[0].amount,
          code   : result.promo.code,
          isValid: true,
        };
        await dispatch({
          type   : typesData.CHECK_PROMOCODE_SUCCESS,
          payload: promo,
        });
      }
      if (result.status !== AWAITING && result.status !== OPEN) {
        const path = redirectToConfirmation(dispatch, getState(), result.status);
        return dispatch(routerPush(path));
      }
      return result;
    } catch (e) {
      window.location.href = `${process.env.REACT_APP_HOMEPAGE}/404`;
      return false;
    }
  };
}

export function setStripePaymentId(stripePaymentId) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_STRIPE_PAYMENT_ID,
      payload: stripePaymentId,
    });
  };
}

export function setPaymentMethod(paymentMethod) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_PAYMENT_METHOD,
      payload: paymentMethod,
    });
  };
}

// GET ORDER WITHOUT SIDE EFFECTS FOR CANCELLATION ORDER
export function getOrderByIdForCancellation(id, token) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ORDER_REQUEST });
      const result = await fetchNoParams(`${ENDPOINTS.getOrder}${id}?expand=promo&expand=services${token ? `&token=${token}` : ''}`);
      const data = {
        bookingCode  : result.bookingCode,
        bookingOrigin: result.bookingOrigin?.platform,
        customer     : result.customer,
        status       : result.status,
        _id          : result._id, // eslint-disable-line
      };
      dispatch({
        type   : types.ORDER_REQUEST_SUCCESS,
        payload: data,
      });
      if (result.services && result.services.length) {
        const address = isDestinationService(result.services[0].type)
          ? result.services[0].to
          : result.services[0].from;
        dispatch({
          type   : types.SET_BOOKING_PARAMETERS,
          payload: result.services[0],
        });
        dispatch({
          type   : types.SET_ADDRESS,
          payload: address,
        });
        dispatch({
          type   : typesData.FLIGHT_INFO_SUCCESS,
          payload: result.services[0].flightInfo,
        });
      }
      if (result.customer) {
        dispatch({
          type   : types.SET_CUSTOMER,
          payload: result.customer,
        });
      }
      const [{ products }] = result.invoices;
      dispatch({
        type   : typesData.PRICE_PRODUCT_SUCCESS,
        payload: products,
      });
      if (result.promo && result.promo.code) {
        const promo = {
          amount : result.invoices[0].amount,
          code   : result.promo.code,
          isValid: true,
        };
        dispatch({
          type   : typesData.CHECK_PROMOCODE_SUCCESS,
          payload: promo,
        });
      }
      return result;
    } catch (e) {
      dispatch({
        type   : types.ORDER_REQUEST_ERROR,
        payload: 'Cancellation.errors.notFound',
      });
      return {};
    }
  };
}

export const cancelOrderFetchRequest = () => ({
  type: types.CANCEL_ORDER_REQUEST,
});

export const cancelOrderFecthSuccess = (payload) => ({
  type: types.CANCEL_ORDER_REQUEST_SUCCESS,
  payload,
});

export const cancelOrderFecthError = (error) => ({
  type: types.CANCEL_ORDER_REQUEST_ERROR,
  error,
});

const validCancelOrderErrors = [
  'Cancellation.errors.backendError',
  'Cancellation.errors.timeExceeded',
];

export const cancelOrderFetch = (orderId, token) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(cancelOrderFetchRequest());
    const body = { token };
    const response = await fetchPostNoValidations(`${ENDPOINTS.orderPath}/${orderId}/cancellation`, body);
    const { code, data } = response;
    const { error } = data;

    if (error || code !== 201) {
      throw new Error('Cancellation.errors.backendError');
    }

    dispatch(cancelOrderFecthSuccess(data));
  } catch (err) {
    const translationPath = validCancelOrderErrors.includes(err.message)
      ? err.message
      : validCancelOrderErrors[0];
    dispatch(cancelOrderFecthError(translationPath));
  }
  dispatch(hideLoading());
};

export const exportsForTesting = {
  updateOrderBooking,
  getPaymentIntent,
};
