export const SET_ADDRESS               = 'BOOKING/SET_ADDRESS';
export const CLEAN_ADDRESS             = 'BOOKING/CLEAN_ADDRESS';
export const SET_BAG_COMMENT           = 'BOOKING/SET_BAG_COMMENT';
export const SET_BOOKING_DELIVERY_TIME = 'BOOKING/SET_BOOKING_DELIVERY_TIME';
export const SET_BOOKING_PARAMETERS    = 'BOOKING/SET_BOOKING_PARAMETERS';
export const SET_BOOKING_PICKUP_TIME   = 'BOOKING/SET_BOOKING_PICKUP_TIME';
export const SET_BOOKING_TYPE          = 'BOOKING/SET_BOOKING_TYPE';
export const SET_BUSINESS_FARE         = 'BOOKING/SET_BUSINESS_FARE';
export const SET_CHECKIN_CLASS_TYPE    = 'BOOKING/SET_CHECKIN_CLASS_TYPE';
export const SET_CHECKIN_PAX_BAGS      = 'BOOKING/SET_CHECKIN_PAX_BAGS';
export const SET_DEPARTURE_DATE        = 'BOOKING/SET_DEPARTURE_DATE';
export const SET_FLIGHT                = 'BOOKING/SET_FLIGHT';
export const SET_SELECTED_FLIGHT_KEYS  = 'BOOKING/SET_SELECTED_FLIGHT';
export const SET_SELECTED_PRODUCT       = 'BOOKING/SET_SELECTED_PRODUCT';
export const SET_PRODUCT               = 'BOOKING/SET_PRODUCT';
export const SET_PAX_BAGS              = 'BOOKING/SET_PAX_BAGS';

export const SET_CUSTOMER                  = 'BOOKING/SET_CUSTOMER';
export const SET_CUSTOMER_NAME             = 'BOOKING/SET_CUSTOMER_NAME';
export const SET_CUSTOMER_SURNAME          = 'BOOKING/SET_CUSTOMER_SURNAME';
export const SET_CUSTOMER_EMAIL            = 'BOOKING/SET_CUSTOMER_EMAIL';
export const SET_CUSTOMER_PHONE            = 'BOOKING/SET_CUSTOMER_PHONE';
export const SET_CUSTOMER_LANGUAGE         = 'BOOKING/SET_CUSTOMER_LANGUAGE';
export const SET_CHECKED_CONDITIONS        = 'BOOKING/SET_CHECKED_CONDITIONS';
export const SET_CHECKED_SUBSCRIBE_UPDATES = 'BOOKING/SET_CHECKED_SUBSCRIBE_UPDATES';

export const UPDATE_STEP               = 'BOOKING/UPDATE_STEP';

export const ORDER_REQUEST             = 'BOOKING/ORDER_REQUEST';
export const ORDER_REQUEST_PAYMENT_REQUIRES_ACTION = 'BOOKING/ORDER_REQUEST_PAYMENT_REQUIRES_ACTION';
export const ORDER_REQUEST_SUCCESS     = 'BOOKING/ORDER_REQUEST_SUCCESS';
export const ORDER_REQUEST_ERROR       = 'BOOKING/ORDER_REQUEST_ERROR';

export const UPDATE_ORDER_REQUEST             = 'BOOKING/UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_REQUEST_ERROR       = 'BOOKING/UPDATE_ORDER_REQUEST_ERROR';
export const UPDATE_ORDER_REQUEST_SUCCESS     = 'BOOKING/UPDATE_ORDER_REQUEST_SUCCESS';

export const SERVICE_BIOMETRIC_REQUEST = 'BOOKING/SERVICE_BIOMETRIC_REQUEST';
export const SERVICE_BIOMETRIC_SUCCESS = 'BOOKING/SERVICE_BIOMETRIC_SUCCESS';
export const SERVICE_BIOMETRIC_ERROR   = 'BOOKING/SERVICE_BIOMETRIC_ERROR';

export const SET_DOWNGRADE             = 'BOOKING/SET_DOWNGRADE';
export const RESET_DOWNGRADE           = 'BOOKING/RESET_DOWNGRADE';

export const SET_REFERRAL              = 'BOOKING/SET_REFERRAL';
export const SET_FREE                  = 'BOOKING/SET_FREE';
export const SET_FREE_ERROR            = 'BOOKING/SET_FREE_ERROR';

export const SET_STRIPE_PAYMENT_ID     = 'BOOKING/SET_STRIPE_PAYMENT_ID';
export const SET_PAYMENT_METHOD        = 'BOOKING/SET_PAYMENT_METHOD';

export const CANCEL_ORDER_REQUEST             = 'BOOKING/CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_REQUEST_ERROR       = 'BOOKING/CANCEL_ORDER_REQUEST_ERROR';
export const CANCEL_ORDER_REQUEST_SUCCESS     = 'BOOKING/CANCEL_ORDER_REQUEST_SUCCESS';
