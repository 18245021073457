import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { palette } from '@bagonboard/ui-kit';
import LoadingBar from 'react-redux-loading-bar';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './style';
import { routes } from '../navigation';
import useIsValidUrl from './useIsValidUrl';
import useRoutesPreloader from './useRoutesPreloader';
import useGTMEvents from './useGTMEvents';
import useWidgetEvents from './useWidgetEvents';
import AppError from './AppError';

import useConnectedHideLoading from './useConnectedHideLoading';
import { setCustomerField } from '../reducers/booking/customer';
import { setAddressField } from '../reducers/booking/address';
import { localeSelector } from '../reducers/locale';
import { isIframeSelector } from '../reducers/widget';
import theme from '../theme';
import useAffiliateLoader from './useAffiliateLoader';
import { serviceSelector, setBookingParameters } from '../reducers/booking/service';
import { pushAffiliatetoGTM } from '../navigation/pages/booking/gtm';

const loadingBarStyle = {
  position       : 'fixed',
  top            : 0,
  zIndex         : 100,
  height         : 3,
  boxShadow      : 'rgba(0, 0, 0, .12) 0 1px 6px, rgba(0, 0, 0, .12) 0 1px 4px',
  backgroundColor: palette.primary,
};

export const AppView = ({ isValidUrl, history }) => {
  const content = isValidUrl ? (
    <ConnectedRouter history={history}><AppError>{routes}</AppError></ConnectedRouter>
  ) : (
    <h2>Redirecting...</h2>
  );
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LoadingBar className="loading-bar" style={loadingBarStyle} />
      {content}
    </ThemeProvider>
  );
};

AppView.propTypes = {
  isValidUrl: PropTypes.bool.isRequired,
  history   : PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const App = ({ history, parameters: { target } }) => {
  const dispatch = useDispatch();
  const isValidUrl = useIsValidUrl(history);
  const locale = useSelector(localeSelector);
  const isIframe = useSelector(isIframeSelector);
  const affiliate = useAffiliateLoader({ history });
  const service = useSelector(serviceSelector);
  const setCustomer = useCallback(
    (name, value) => dispatch(setCustomerField(name, value)),
    [dispatch],
  );
  const setAddress = useCallback(
    (name, value) => dispatch(setAddressField(name, value)),
    [dispatch],
  );

  useConnectedHideLoading(isValidUrl);
  useRoutesPreloader(isValidUrl);
  useGTMEvents({
    isValidUrl,
    history,
    locale,
    isIframe,
  });
  useWidgetEvents({
    isValidUrl,
    isIframe,
    setCustomer,
    setAddress,
    target,
  });
  useEffect(() => {
    dispatch(setBookingParameters({ ...service, affiliate }));
    pushAffiliatetoGTM(affiliate);
  },
  // eslint-disable-next-line
  [affiliate]);
  return <AppView history={history} isValidUrl={isValidUrl} />;
};

App.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  parameters: PropTypes.shape({
    target: PropTypes.string.isRequired,
  }).isRequired,
};

export default App;
