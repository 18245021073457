import { createSelector } from 'reselect';
import {
  defaultTo, pipe, prop, propOr, ORDER_UNCONFIRMED_STATUSES, getPaxsTotalBags,
} from '@bagonboard/wiseman';
import {
  applySpec, path, pathOr, map, sum, ifElse, hasPath, always,

} from 'ramda';

const getErrorCode = propOr('generic_error', 'code');
const defaultToStr = defaultTo('');

export const orderSelector = (state) => state.booking.order;

const makeOrderPropSelector = (key) => pipe(
  orderSelector,
  prop(key),
);

export const orderIsLoadingSelector = makeOrderPropSelector('isLoading');
export const orderErrorSelector = makeOrderPropSelector('error');

export const orderErrorMsgSelector = createSelector(
  orderErrorSelector,
  (error) => (error ? getErrorCode(error) : null),
);

export const orderBookedSelector = makeOrderPropSelector('orderBooked');

const makeOrderBookedPropSelector = (key) => pipe(
  orderBookedSelector,
  prop(key),
);

export const orderBookingCodeSelector = makeOrderBookedPropSelector('bookingCode');
export const orderErrorCodeSelector = makeOrderBookedPropSelector('errorCode');
export const orderIdSelector = makeOrderBookedPropSelector('_id');
export const orderStatusSelector = makeOrderBookedPropSelector('status');
export const orderPaymentSelector = makeOrderBookedPropSelector('payment');
export const orderCustomerSelector = makeOrderBookedPropSelector('customer');
export const orderProductsSelector = makeOrderBookedPropSelector('products');
export const orderInvoiceSelector = makeOrderBookedPropSelector('invoice');
export const orderBookingOriginSelector = createSelector(
  orderBookedSelector,
  prop('bookingOrigin'),
);

export const orderClientSecretSelector = createSelector(
  orderPaymentSelector,
  prop('clientSecret'),
);

export const orderCustomerSafeSelector = createSelector(
  orderCustomerSelector,
  pipe(
    defaultTo({}),
    (customer) => {
      const { name, surname } = customer;
      return {
        ...customer,
        name   : defaultToStr(name),
        surname: defaultToStr(surname),
      };
    }
  ),
);

const sumInvoiceProductsPrices = ifElse(
  hasPath(['order', 'orderBooked', 'invoice', 'products']),
  pipe(
    path(['order', 'orderBooked', 'invoice', 'products']),
    map(path(['price'])),
    sum
  ),
  always(0)
);

export const orderWidgetEventSelector = createSelector(
  (state) => state.booking,
  applySpec({
    bookingCode : path(['order', 'orderBooked', 'bookingCode']),
    language    : path(['customer', 'language']),
    numBags     : pipe(path(['service', 'paxs']), getPaxsTotalBags),
    orderId     : path(['order', 'orderBooked', '_id']),
    price       : sumInvoiceProductsPrices,
    type        : path(['order', 'orderBooked', 'products', 0, 'detail']),
    isSubsidized: pathOr(false, ['order', 'orderBooked', 'invoice', 'free']),
    status      : path(['order', 'orderBooked', 'status']),
  })
);

export const orderPaymentMethodSelector = makeOrderPropSelector('paymentMethod');
export const orderRequiresActionSelector = makeOrderPropSelector('requiresAction');

export const orderIsConfirmedSelector = createSelector(
  orderStatusSelector,
  (status) => !ORDER_UNCONFIRMED_STATUSES.includes(status)
);
