import storage from './storage';

class QueryParamsPersist {
  static storageKey = 'queryParams';

  static of(persistStorage) {
    return new QueryParamsPersist(persistStorage);
  }

  constructor(persistStorage) {
    this.storage = persistStorage;
  }

  get = () => {
    const value = this.storage.getItem(QueryParamsPersist.storageKey);
    return value ? JSON.parse(value) : {};
  };

  save = (newValue) => {
    this.storage.setItem(QueryParamsPersist.storageKey, JSON.stringify(newValue));
  };

  clear = () => {
    this.storage.removeItem(QueryParamsPersist.storageKey);
  };
}

const queryParamsPersist = QueryParamsPersist.of(storage);

export default queryParamsPersist;
