import { fetchPost } from '../api';

async function fetchMultiFlightSchedule(segments, flights) {
  const urlTerminal = '/flight/multi-schedule';
  try {
    const body = {
      flightSegments: segments,
      flightNumbers : flights,
    };
    const data = await fetchPost(urlTerminal, body);
    return data;
  } catch (error) {
    return undefined;
  }
}

export default fetchMultiFlightSchedule;
