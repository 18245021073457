const loadingId = process.env.REACT_APP_LOADING_IDs || 'booking--loading';

export const hideLoading = (delay = 250) => {
  const loadingElement = document.getElementById(loadingId);
  if (
    !(loadingElement?.classList?.add
    && typeof loadingElement.classList.add === 'function')
  ) {
    return;
  }
  loadingElement.classList.add('fade-out');
  if (!delay) {
    if (typeof loadingElement.remove === 'function') loadingElement.remove();
    return;
  }
  setTimeout(() => {
    if (typeof loadingElement.remove === 'function') {
      loadingElement.remove();
    }
  }, delay);
};
