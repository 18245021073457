const CONSTANTS = {
  scrollDefaultOptions: { duration: 500, offset: -80, smooth: true },
  pnrRetrieveAirlines : ['IB'], // Update if we integrate a new Airline
  pnrDefaultAirlines  : ['IB'], // Airlines using PNR as default search
  partnershipAirlines : ['AF', 'IB', 'I2', 'KL', 'LA', 'YW', '2W', 'UX'], // Update if a new airline is added
  operableAirports    : ['MAD', 'BCN', 'LCG'], // Update if a new area is added
  homepageUrls        : { // Update with WordPress URLs
    IB  : `${process.env.REACT_APP_HOMEPAGE}/iberia`,
    VY  : `${process.env.REACT_APP_HOMEPAGE}/vueling`,
    LH  : `${process.env.REACT_APP_HOMEPAGE}/lufthansa`,
    AF  : `${process.env.REACT_APP_HOMEPAGE}/airfrance`,
    KL  : `${process.env.REACT_APP_HOMEPAGE}/klm`,
    I2  : `${process.env.REACT_APP_HOMEPAGE}/iberiaexpress`,
    HF  : `${process.env.REACT_APP_HOMEPAGE}/iberia/handsfree`,
    '2W': `${process.env.REACT_APP_HOMEPAGE}/world2fly/`,
    // U2: `${process.env.REACT_APP_HOMEPAGE}/easyjet`, wait to business to approve this one
  },
  endpoints: {
    PROMO_URL: 'promo/check',
  },
  flightRetrieveMethod: {
    MANUAL: 'manual',
    PNR   : 'pnr',
  },
  addressType: {
    ORIGIN_AIRPORT     : 'originAirport',
    DESTINATION_AIRPORT: 'destinationAirport',
    STREET             : 'street',
  },
  landings: {
    HANDSFREE: 'handsfree',
  },
  paymentMethods: { // Remember update locales with the same values if a new payment method is added
    STRIPE: 'stripe',
  },
  availableLanguages: ['en', 'es'],
  gmapsLibraries    : ['geometry', 'drawing', 'places'],
};

export default CONSTANTS;
