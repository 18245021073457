import { createStore, applyMiddleware, compose } from 'redux';
import thunk                                     from 'redux-thunk';
import { routerMiddleware }                      from 'connected-react-router';
import { loadingBarMiddleware }                  from 'react-redux-loading-bar';
import rootReducer                               from '../reducers';
import history                                   from '../history';

const loadingBarConfig = {
  promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'],
};

export default (initialState = {}, otherMiddlewares = []) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        loadingBarMiddleware(loadingBarConfig),
        ...otherMiddlewares.map((middleware) => middleware())
      )
    )
  );
};
