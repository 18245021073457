const FLIGHT_LENGTH_LIMITS = [
  { flightLength: 'short-haul', limit: 1500 },
  { flightLength: 'mid-haul', limit: 4000 },
  { flightLength: 'long-haul', limit: null },
];
const EARTH_RADIUS = 6371;

const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

function distanceInKmBetweenEarthCoordinates({ lat: lat1, lng: lon1 }, { lat: lat2, lng: lon2 }) {
  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
          + Math.sin(dLon / 2) * Math.sin(dLon / 2)
          * Math.cos(degreesToRadians(lat1))
          * Math.cos(degreesToRadians(lat2));

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS * c;
}

export const calculateFlightHaulType = (fromLocation, toLocation) => {
  const distance = distanceInKmBetweenEarthCoordinates(fromLocation, toLocation);
  const { flightLength } = FLIGHT_LENGTH_LIMITS.find((fl) => !fl.limit || distance < fl.limit);
  return flightLength;
};
