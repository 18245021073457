import * as types from '../types';

const fields = {
  name                   : types.SET_CUSTOMER_NAME,
  surname                : types.SET_CUSTOMER_SURNAME,
  email                  : types.SET_CUSTOMER_EMAIL,
  phone                  : types.SET_CUSTOMER_PHONE,
  language               : types.SET_CUSTOMER_LANGUAGE,
  checkedConditions      : types.SET_CHECKED_CONDITIONS,
  checkedSubscribeUpdates: types.SET_CHECKED_SUBSCRIBE_UPDATES,
};

export function setCustomerField(name, value) {
  return (dispatch) => {
    const type = fields[name];
    if (!type) return;
    dispatch({
      type,
      payload: value,
    });
  };
}
