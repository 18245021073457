import { createSelector } from 'reselect';
import {
  defaultTo, prop, pipe, propOr,
} from '@bagonboard/wiseman';

import { serviceSelectedFlightKeysSelector, serviceSelectedProductIdSelector } from './common';
import { flightsDataSelector } from '../../../data/flights';
import { flightDataSelector as flightDataSourceSelector } from '../../../data/flightInfo';

export const serviceSelectedFlightKeysObjSelector = createSelector(
  serviceSelectedFlightKeysSelector,
  (selectedKeys) => {
    if (!selectedKeys) {
      return null;
    }

    const [
      flightNumber,
      originDate,
      destinationDate,
      airline,
      airlineOnDestination,
      marketer,
      operator,
      operatorOnDestination,
      flightNumbers,
    ] = selectedKeys.split('#');
    return {
      flightNumber,
      flightNumbers: flightNumbers.split('-'),
      origin       : {
        date: originDate,
      },
      destination: {
        date: destinationDate,
      },
      airline,
      airlineOnDestination,
      marketer,
      operator,
      operatorOnDestination,
    };
  }
);

const defaultToEmptyArr = defaultTo([]);

const isSameFlight = (obj1) => (obj2) => [
  'origin',
  'destination',
  'flightNumber',
  'airline',
  'airlineOnDestination',
  'marketer',
  'operator',
  'operatorOnDestination',
  'flightNumbers',
].reduce((result, curr, _, arr) => {
  if (!result) {
    // mutate the array of keys to eject ASAP when some property is not equal
    arr.splice(1);
    return false;
  }
  const getCurrentProp = prop(curr);

  const obj1Value = getCurrentProp(obj1);
  const obj2Value = getCurrentProp(obj2);
  if (curr === 'origin' || curr === 'destination') {
    return obj1Value.date === obj2Value.date;
  }

  if (curr === 'flightNumbers') {
    return defaultToEmptyArr(obj1Value).join('-') === defaultToEmptyArr(obj2Value).join('-');
  }

  return obj1Value === '' || obj1Value === obj2Value;
}, true);

export const serviceSelectedFlightWithProductsSelector = createSelector(
  serviceSelectedFlightKeysObjSelector,
  flightsDataSelector,
  (selectedFlightKeys, flights) => {
    if (!selectedFlightKeys) {
      return undefined;
    }

    const isSameFlightThanSelected = isSameFlight(selectedFlightKeys);
    const flightDataIsSameThanSelected = pipe(
      (item) => item?.flight?.flightData,
      isSameFlightThanSelected
    );
    return flights.find(flightDataIsSameThanSelected);
  }
);

export const serviceSelectedFlightSelector = createSelector(
  serviceSelectedFlightWithProductsSelector, // Listado de vuelos
  prop('flight') // service flight
);

export const flightPaxsSelector = createSelector(
  serviceSelectedFlightSelector,
  prop('paxs')
);

const getSafeFlightData = propOr({}, 'flightData');

export const flightDataSelector = createSelector(
  serviceSelectedFlightSelector,
  flightDataSourceSelector,
  (selectedFlight, flightData) => {
    const safeFlightData = getSafeFlightData(selectedFlight);
    return {
      ...safeFlightData,
      ...flightData,
      flightNumbers: safeFlightData.flightNumbers || flightData.flightNumbers,
    };
  }
);

export const flightFromCodeSelector = createSelector(
  flightDataSelector,
  ({ origin }) => origin.airportCode
);

export const flightToCodeSelector = createSelector(
  flightDataSelector,
  ({ destination }) => destination.airportCode
);

export const flightFromToSelector = createSelector(
  flightFromCodeSelector,
  flightToCodeSelector,
  (fromCode, toCode) => ({
    fromCode,
    toCode,
  })
);

export const flightFromToCitySelector = createSelector(
  flightDataSelector,
  ({ origin, destination }) => ({
    from: origin.city,
    to  : destination.city,
  })
);

export const flightOperatorSelector = createSelector(
  flightDataSelector,
  prop('operator')
);

export const flightProductsSelector = createSelector(
  serviceSelectedFlightWithProductsSelector,
  propOr([], 'products')
);

export const flightServicesSelector = createSelector(
  flightProductsSelector,
  (products) => products.map(prop('service')),
);

export const flightIsMultiserviceSelector = createSelector(
  flightProductsSelector,
  (products) => products.length > 1
);

export const flightFirstProductSelector = createSelector(
  flightProductsSelector,
  (products) => products[0]
);

export const getProductId = pipe(
  prop('product'),
  prop('_id'),
);

export const flightFirstProductIdSelector = createSelector(
  flightFirstProductSelector,
  getProductId,
);

export const flightSelectedProductSelector = createSelector(
  serviceSelectedProductIdSelector,
  flightProductsSelector,
  (selectedProductId, products) => products.find(
    pipe(getProductId, (productId) => productId === selectedProductId)
  )
);
