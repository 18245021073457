// import diff from 'deep-diff';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import storage from 'store';
import qs from 'stringquery';
import history from '../../history';

const availableLocales = ['en', 'es'];
const defaultLocale = 'en';

/* Populate locales with default literals */
const existLocale = (loc) => availableLocales.includes(loc);
const getLocale = (loc) => {
  const selectedLocale = existLocale(loc)
  // eslint-disable-next-line global-require, import/no-dynamic-require
    ? require(`./${loc}.json`)
  // eslint-disable-next-line global-require, import/no-dynamic-require
    : require(`./${defaultLocale}.json`);
  return selectedLocale;
};

export default (store) => {
  /* Is locale given by parent */
  const queries = history.location.search;
  const parsed = qs(queries);
  let locale;
  if (parsed && parsed.lang) {
    const isGoodLocale = availableLocales.includes(parsed.lang);
    locale = isGoodLocale ? parsed.lang : defaultLocale;
  } else {
    /* Locale Autodetection */
    const storedLocale = storage.get('LOCALE');
    const browserLocale = (
      (window.navigator.languages ? window.navigator.languages[0] : null)
      || window.navigator.language
      || window.navigator.browserLanguage
      || window.navigator.userLanguage
      || defaultLocale
    ).substr(0, 2).toLowerCase();
    if (existLocale(storedLocale)) {
      locale = storedLocale;
    } else if (existLocale(browserLocale)) {
      locale = browserLocale;
    } else {
      locale = defaultLocale;
    }
  }
  // Update cookie for Drift
  document.cookie = `bob_lang=${locale}`;
  const translations = availableLocales.reduce((acc, language) => ({
    ...acc,
    [language]: getLocale(language),
  }), {});

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));
  store.dispatch(setLocale(locale));
};
