import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from '../paths';
import pages from '../pages';
import CONSTANTS from '../../lib/constants';

const routes = (
  <div>
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={paths.routerNoPartner} component={pages.NoPartner} />
        <Route exact path={paths.routerPnr} component={pages.ServiceSelector} />
        <Route
          exact
          path={paths.routerFromToDate}
          component={pages.ServiceSelector}
        />
        <Route
          exact
          path={paths.routerFromToDateResults}
          render={(props) => (
            <pages.Results
              {...props}
              flightRetrieveMethod={CONSTANTS.flightRetrieveMethod.MANUAL}
            />
          )}
        />
        <Route
          exact
          path={paths.routerFromToDateNoResults}
          render={(props) => (
            <pages.Results
              {...props}
              flightRetrieveMethod={CONSTANTS.flightRetrieveMethod.MANUAL}
            />
          )}
        />
        <Route
          exact
          path={paths.routerPnrResults}
          render={(props) => (
            <pages.Results
              {...props}
              flightRetrieveMethod={CONSTANTS.flightRetrieveMethod.PNR}
            />
          )}
        />
        <Route
          exact
          path={paths.routerPnrNoResults}
          render={(props) => (
            <pages.ServiceSelector
              {...props}
              flightRetrieveMethod={CONSTANTS.flightRetrieveMethod.PNR}
            />
          )}
        />
        <Route exact path={paths.routerServices} component={pages.ServicesList} />
        <Route
          exact
          path={paths.routerNoAvailability}
          component={pages.NoAvailability}
        />
        <Route
          exact
          path={paths.routerCheckin}
          component={pages.ConfirmationFlight}
        />
        <Route
          exact
          path={paths.routerDeliveryAirport}
          component={pages.ConfirmationFlight}
        />
        <Route
          exact
          path={paths.routerDeliveryCity}
          component={pages.ConfirmationFlight}
        />
        <Route
          exact
          path={paths.routerSkipBaggageClaim}
          component={pages.ConfirmationFlight}
        />
        <Route
          exact
          path={paths.serviceSelector.flights}
          component={pages.Flights}
        />
        <Route
          exact
          path={paths.serviceSelector.flightConfirmation}
          component={pages.ConfirmationFlight}
        />
        <Route path={paths.steps} component={pages.Steps} />
        <Route
          exact
          path={paths.confirmationCheckin}
          component={pages.Confirmation}
        />
        <Route
          exact
          path={paths.confirmationDeliveryAirport}
          component={pages.Confirmation}
        />
        <Route
          exact
          path={paths.confirmationDeliveryCity}
          component={pages.Confirmation}
        />
        <Route
          exact
          path={paths.confirmationSkipBaggageClaim}
          component={pages.Confirmation}
        />
        <Route exact path={paths.paymentError} component={pages.Confirmation} />
        <Route exact path={paths.iata} component={pages.IATA} />
        <Route exact path={paths.payment} component={pages.PaymentScreen} />
        <Route exact path={paths.biometric} component={pages.BiometricScreen} />
        <Route path={paths.cancellation} component={pages.CancellationScreen} />
        <Route path={paths.pirInfo.auth} component={pages.PIRInfoScreen} />
        <Route render={() => <div>Route not found</div>} />
      </Switch>
    </Suspense>
  </div>
);

export default routes;
