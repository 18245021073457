import { useRef, useEffect } from 'react';
import { subscribeToMessages, sendMessageTo } from '../lib/widget';

const errors = {
  invalidFields: 'Invalid field/s: ',
};

export const fields = {
  address : ['pickupAddress', 'pickupAddressExtra'],
  customer: ['name', 'surname', 'email', 'phone', 'language'],
};

/**
 * Checks that every field received is in the fields list
 * @param {Object} payload
 */
export const assertFields = (payload) => {
  const invalidFields = Object.keys(payload)
    .filter((field) => !([...fields.address, ...fields.customer]).includes(field));
  if (invalidFields.length > 0) {
    const errorMessage = `${errors.invalidFields}: ${invalidFields.join(',')}`;
    // eslint-disable-next-line no-console
    console.error(errorMessage);
  }
};

/**
 * Filters the received payload props to match with the provided fieldList
 * for every result then executes the action
 * @param {Object}   payload payload received in the message
 * @param {Array<String>}   fieldsList field list that the action can process
 * @param {function} action action dispatcher
 * @retu
  */
export const filterFieldsSetValue = (payload, fieldsList, action) => Object.entries(payload)
  .filter(([name]) => fieldsList.includes(name))
  .forEach(([name, value]) => {
    action(name, value);
  });

/**
 * Returns an object, every key must match the received message type to be executed
 * @param {function} param0.setCustomer action to dispatch customer fields
 * @param {function} param0.setAddress action to dispatch address fields
 * @returns {function(Object)} handlers
 */
export const messageHandlers = ({ setCustomer, setAddress }) => ({
  setBookerPersonalInfo: ({ payload }) => {
    assertFields(payload);
    filterFieldsSetValue(payload, fields.customer, setCustomer);
    filterFieldsSetValue(payload, fields.address, setAddress);
  },
});

export const execWidgetActions = ({
  isIframe,
  isValidUrl,
  setCustomer,
  setAddress,
  widgetEventSent,
  target,
}) => {
  if (isIframe && isValidUrl && !widgetEventSent.current && target) {
    subscribeToMessages(messageHandlers({ setCustomer, setAddress }), target);
    sendMessageTo('bobWidgetIsReady');
    // eslint-disable-next-line no-param-reassign
    widgetEventSent.current = true;
  }
};

const useWidgetEvents = ({
  isValidUrl, isIframe, setCustomer, setAddress, target,
}) => {
  const widgetEventSent = useRef(false);

  useEffect(() => {
    execWidgetActions({
      isIframe,
      isValidUrl,
      setCustomer,
      setAddress,
      widgetEventSent,
      target,
    });
  }, [isIframe, isValidUrl, setCustomer, setAddress, target]);
};

export default useWidgetEvents;
