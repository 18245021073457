import { ICONS } from '@bagonboard/ui-kit';
import { accentFold } from '@bagonboard/wiseman';

/**
 * Map airportList to autocomplete values if it has not been mapped before
 * Otherwise, return already mapped version (memoized static response)
 * @param {Object[]} airportsList
 */
export const mapAirportsListToAutocomplete = (airportsList = []) => airportsList.map((airport) => ({
  ...airport,
  icon: ICONS.AIRFILLUP,
  name: airport.airportName,
}));

/**
 * Find Airport by IATA code
 * @function findAirport
 * @param  {Array} airportsList List of airports
 * @param  {String} code IATA code to find
 * @return {Object} Airport found by its IATA code
 */
export const findAirport = (airportsList = [], code) => (
  airportsList.find((airport) => airport.airportCode === code) || {});

/**
 *
 * @param {String} inputValue Value read by input
 * @param {Object} option Airport option
 */
export const filterAirportSelect = (inputValue) => (option) => {
  if (option && option.city && option.country) {
    const city = accentFold(option.city.toLowerCase());
    const value = accentFold(inputValue.toLowerCase());
    const country = accentFold(option.country.toLowerCase());
    return city.includes(value) || country.includes(value);
  }
  return false;
};

/**
 *
 * @param {Object} option Airport option
 */
export const formatAirportTitleOnSelect = (option) => {
  if (option && option.city && option.name && option.airportCode) {
    return `${option.city} - ${option.name} (${option.airportCode})`;
  }
  return option;
};
