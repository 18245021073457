import { combineReducers } from 'redux';
import { customer } from './customer/reducer';
import { address } from './address/reducer';
import order from './order/reducer';
import service from './service/reducer';
import parameters from './parameters/reducer';

const bookingReducer = combineReducers({
  service,
  address,
  customer,
  order,
  parameters,
});

export default bookingReducer;
