import styled from 'styled-components';
import { Button } from '@bagonboard/ui-kit';

import { mediaQueries } from '../../styles/widths';

export const ButtonDesktop = styled(Button)`
  display: none;

  ${mediaQueries.desktop} {
    display: initial;
  }
`;
ButtonDesktop.displayName = 'ButtonDesktop';
