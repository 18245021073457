export const integratorThemeLookUp = {
  IB: () => import('./IB/theme.scss'),
  UX: () => import('./UX/theme.scss'),
};

const applyIntegratorTheme = (code) => {
  const applyTheme = integratorThemeLookUp[code];
  if (applyTheme) {
    applyTheme();
  }
};

export default applyIntegratorTheme;
