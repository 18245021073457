/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getHotjarUserId } from '../hotjar';

let axiosConfig = {};

export function requestInterceptorHeaders(config) {
  const { isIframe, parameters } = axiosConfig;
  const originHeader = isIframe ? 'widget' : 'web';
  const hotjarUserId = getHotjarUserId();
  config.headers['Bob-Origin-Platform'] = originHeader;
  if (hotjarUserId) {
    config.headers['Bob-Record-User'] = hotjarUserId;
  }
  if (isIframe) {
    config.headers['Bob-Widget-Owner'] = parameters['widget-owner'];
  }
  return config;
}

function getBackendInstance(timeout) {
  const instance = axios.create({
    baseURL        : process.env.REACT_APP_BOB_API,
    withCredentials: true,
    ...(timeout && { timeout }),
  });
  instance.interceptors.request.use(requestInterceptorHeaders);
  return instance;
}

function getS3Instance(timeout) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_S3_URL,
    ...(timeout && { timeout }),
  });
  return instance;
}

const Axios = {
  init: (isIframe, parameters) => {
    axiosConfig = { isIframe, parameters };
  },
  getBackendInstance,
  getS3Instance,
};

Object.freeze(Axios);
export default Axios;
