import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  paths,
} from '../navigation';

import { homePageRedirect } from '../lib/redirections';
import { serviceMarketerSelector } from '../reducers/booking/service';

const validUrls = [
  paths.iata,
  paths.payment,
  paths.widget,
  paths.routerNoPartner,
  paths.routerPnr,
  paths.step,
  paths.biometric,
  paths.pirInfo.auth,
  paths.pirInfo.summary,
  paths.cancellation,
];

export const redirectIfNeeded = (isValidUrl, marketer) => {
  if (!isValidUrl) {
    // eslint-disable-next-line no-param-reassign
    homePageRedirect(marketer);
  }
};

const useIsValidUrl = (history) => {
  const isValidUrl = useMemo(() => validUrls.includes(history.location.pathname), [history]);
  const marketer = useSelector(serviceMarketerSelector);
  useEffect(() => {
    redirectIfNeeded(isValidUrl, marketer);
  }, [isValidUrl, marketer]);
  return isValidUrl;
};

export default useIsValidUrl;
