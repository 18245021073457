import { setLocale as reduxSetLocale } from 'react-redux-i18n';
import { push } from 'connected-react-router';
import storage from 'store';

// eslint-disable-next-line import/prefer-default-export
export function setLocale(locale) {
  return (dispatch) => {
    storage.set('LOCALE', locale);
    dispatch(reduxSetLocale(locale));
    dispatch(push(`?lang=${locale.toString().toLowerCase()}`));
  };
}
