import { defaultTo } from '@bagonboard/wiseman';

import * as types from '../types';

const initialState = null;

const defaultToObj = defaultTo({});

export const address = (
  state = initialState,
  action
) => {
  const safeState = defaultToObj(state);
  switch (action.type) {
    case types.SET_ADDRESS:
      return {
        ...safeState,
        ...action.payload,
      };
    case types.CLEAN_ADDRESS:
      return {
        streetExtra: safeState.streetExtra,
      };
    default:
      return state;
  }
};
