import { lazyWithPreload } from '../utils';

const Address = lazyWithPreload(() => import(/* webpackChunkName: 'Address' */'./booking/pickupAddress'));
const ServiceSelector = lazyWithPreload(() => import(/* webpackChunkName: 'ServiceSelector' */'./serviceSelector'));
// TODO: Check use
const ConfirmationFlight = lazyWithPreload(() => import(/* webpackChunkName: 'ConfirmationFlight' */'./serviceSelector/confirmation'));
const NoAvailability = lazyWithPreload(() => import(/* webpackChunkName: 'NoAvailability' */'./serviceSelector/noAvailability'));
// TODO: Check use
const Flights = lazyWithPreload(() => import(/* webpackChunkName: 'Flights' */'./serviceSelector/flights'));
const Results = lazyWithPreload(() => import(/* webpackChunkName: 'Results' */'./serviceSelector/results'));
const ServicesList = lazyWithPreload(() => import(/* webpackChunkName: 'ServicesList' */'./serviceSelector/list'));
const Bags = lazyWithPreload(() => import(/* webpackChunkName: 'Bags' */'./booking/bags'));
const Confirmation = lazyWithPreload(() => import(/* webpackChunkName: 'Confirmation' */'./booking/confirmation'));
const PickupTime = lazyWithPreload(() => import(/* webpackChunkName: 'PickupTime' */'./booking/pickupTime'));
const DeliveryTime = lazyWithPreload(() => import(/* webpackChunkName: 'DeliveryTime' */'./booking/deliveryTime'));
const Contact = lazyWithPreload(() => import(/* webpackChunkName: 'Contact' */'./booking/contact'));
const PaymentWrapper = lazyWithPreload(() => import(/* webpackChunkName: 'PaymentWrapper' */'./booking/payment'));
const Steps = lazyWithPreload(() => import(/* webpackChunkName: 'Steps' */'./booking'));
const NoPartner = lazyWithPreload(() => import(/* webpackChunkName: 'NoPartner' */'./noPartner'));
const IATA = lazyWithPreload(() => import(/* webpackChunkName: 'IATA' */'./iata'));
const PaymentScreen = lazyWithPreload(() => import(/* webpackChunkName: 'PaymentScreen' */'./payment'));
const BiometricScreen = lazyWithPreload(() => import(/* webpackChunkName: 'BiometricScreen' */'./biometric'));
const PIRInfoScreen = lazyWithPreload(() => import(/* webpackChunkName: 'PIRInfoScreen' */'./PIRInfo'));
const CancellationScreen = lazyWithPreload(() => import(/* webpackChunkName: 'CancellationScreen' */'./cancellation'));

export default {
  Address,
  Bags,
  Confirmation,
  ConfirmationFlight,
  NoAvailability,
  PickupTime,
  DeliveryTime,
  Contact,
  Flights,
  Results,
  ServicesList,
  NoPartner,
  ServiceSelector,
  Payment: PaymentWrapper,
  Steps,
  IATA,
  PaymentScreen,
  BiometricScreen,
  PIRInfoScreen,
  CancellationScreen,
};
