import * as types from '../types';

const initialState = {
  selectedFlightKeys: null,
  paxs              : [{ numBags: 1 }],
  product           : {},
};

const service = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.SET_BOOKING_PARAMETERS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_BOOKING_DELIVERY_TIME:
      return {
        ...state,
        deliveryTime: action.payload.deliveryTime,
        deliverySlot: action.payload.timeSlot,
        timezone    : action.payload.timezone,
      };
    case types.SET_BOOKING_PICKUP_TIME:
      return {
        ...state,
        pickupTime: action.payload.pickupTime,
        pickupSlot: action.payload.timeSlot,
        timezone  : action.payload.timezone,
      };
    case types.SET_BOOKING_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case types.SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.payload,
      };
    case types.SET_FLIGHT:
      return {
        ...state,
        flight: {
          ...state.flight,
          ...action.payload,
        },
      };
    case types.SET_SELECTED_FLIGHT_KEYS:
      return {
        ...state,
        selectedFlightKeys: action.payload,
      };
    case types.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case types.SET_BAG_COMMENT:
      return {
        ...state,
        bagComment: action.payload,
      };
    case types.SET_PAX_BAGS:
      return (Array.isArray(state.paxs) && state.paxs.length > 0)
        ? {
          ...state,
          paxs: state.paxs.map(
            (pax, i) => (i === action.payload.pax
              ? { ...pax, numBags: action.payload.numBags }
              : pax)
          ),
        }
        : state;
    case types.SET_CHECKIN_CLASS_TYPE:
      return {
        ...state,
        flightClass: action.payload,
      };
    case types.SET_CHECKIN_PAX_BAGS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_BUSINESS_FARE:
      return {
        ...state,
        business: action.payload,
      };
    case types.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case types.SET_DOWNGRADE:
      return {
        ...state,
        downgrade: action.payload,
      };
    case types.RESET_DOWNGRADE:
      return {
        ...state,
        downgrade: undefined,
      };
    case types.SET_FREE:
      return {
        ...state,
        isFree: action.payload,
      };
    case types.SERVICE_BIOMETRIC_REQUEST:
      return {
        ...state,
        isLoading: true,
        error    : false,
        ...action.payload,
      };
    case types.SERVICE_BIOMETRIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        biometric: action.payload,
      };
    case types.SERVICE_BIOMETRIC_ERROR:
      return {
        ...state,
        isLoading: false,
        error    : action.payload,
      };
    default:
      return state;
  }
};

export default service;
