import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { getHotjarUserId } from '../hotjar';

/**
 * Sentry event modifications before sending to service
 * @param {Object} event Event object built by sentry on exception
 * @returns {Object} modified Event
 */
const beforeSend = (event) => {
  const hotjarUserId = getHotjarUserId();
  let { extra } = event;
  if (hotjarUserId) {
    extra = { ...extra, hotjarUserId };
  }

  return {
    ...event,
    fingerprint: ['{{ default }}'],
    ...(extra && { extra }),
  };
};

const initializeSentry = () => {
  if (process.env.REACT_APP_SENTRY_ENABLED) {
    const versionMatch = (process.env.REACT_APP_VERSION || '').match(/\d+\.\d+/);
    const release = versionMatch ? `booking@${versionMatch}` : '-';
    Sentry.init({
      dsn        : 'https://42364b1b4cb243f6abd810ac844fb2a5@sentry.io/1412657',
      environment: process.env.REACT_APP_ENVIRONMENT,
      release,
      beforeSend,
    });
  }
};

const getSentryReduxMiddleware = () => createSentryMiddleware(Sentry, {
  stateTransformer: () => ({}),
});

export { initializeSentry, beforeSend, getSentryReduxMiddleware };
