import {
  PIR_INFO_FETCH,
  PIR_INFO_FETCH_SUCCESS,
  PIR_INFO_FETCH_ERROR,
} from './types';

const INITIAL_STATE = {
  data   : null,
  error  : null,
  loading: false,
};

const reducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case PIR_INFO_FETCH:
      return {
        ...state,
        error  : null,
        loading: true,
      };
    case PIR_INFO_FETCH_SUCCESS:
      return {
        ...state,
        data   : action.payload,
        loading: false,
      };
    case PIR_INFO_FETCH_ERROR:
      return {
        ...state,
        error  : action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
