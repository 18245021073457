import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, BOOKING_ORIGIN } from '@bagonboard/wiseman';

import { paths } from '../navigation';

import {
  pushPageTypeToGTM,
  pushLanguageToGTM,
  pushIsIframeVariabletoGTM,
  pushBookingOriginToGTM,
} from '../navigation/pages/booking/gtm';
import {
  orderIsLoadingSelector,
  orderBookingOriginSelector,
} from '../reducers/booking/order';

const BOOKING_START_PATHS = [paths.routerPnr, paths.routerNoPartner];

export const execIframeActionsWhenNeeded = ({
  isIframe,
  isValidUrl,
  iframeEventSent,
}) => {
  if (isIframe && isValidUrl && !iframeEventSent.current) {
    pushIsIframeVariabletoGTM();
    // eslint-disable-next-line no-param-reassign
    iframeEventSent.current = true;
  }
};

export const makeHistoryCB = (locale) => ({ pathname }) => {
  // Send common events
  pushPageTypeToGTM(pathname);
  pushLanguageToGTM(locale);
};

export const listenToHistoryWhenNeeded = ({
  isValidUrl,
  history,
  historyCB,
}) => (isValidUrl ? history.listen(historyCB) : undefined);

export const execBookingOriginActionsWhenNeeded = ({
  isValidUrl,
  isIframe,
  bookingEventSent,
  bookingOrigin,
  pathname,
  orderIsLoading,
}) => {
  if (isValidUrl && !bookingEventSent.current) {
    const getSafeBookingOrigin = defaultTo(isIframe ? BOOKING_ORIGIN.WIDGET : BOOKING_ORIGIN.WEB);
    const safeBookingOrigin = getSafeBookingOrigin(bookingOrigin);
    const isPaymentPath = pathname === paths.payment;
    const shouldSendBecausePayment = isPaymentPath && !orderIsLoading && bookingOrigin;
    const shouldSendBecauseFirstStep = BOOKING_START_PATHS.includes(
      pathname
    );

    if (shouldSendBecausePayment || shouldSendBecauseFirstStep) {
      pushBookingOriginToGTM(safeBookingOrigin);
      // eslint-disable-next-line no-param-reassign
      bookingEventSent.current = true;
    }
  }
};

const useGTMEvents = ({
  isValidUrl, history, locale, isIframe,
}) => {
  // We want to store the flag in a reference, to persist its value even in the case of
  // the component reloads for some strange reason
  const bookingEventSent = useRef(false);
  const iframeEventSent = useRef(false);

  // wait for order to be loaded
  const orderIsLoading = useSelector(orderIsLoadingSelector);
  const bookingOrigin = useSelector(orderBookingOriginSelector);

  useEffect(() => {
    execIframeActionsWhenNeeded({
      isIframe,
      isValidUrl,
      iframeEventSent,
    });
  }, [isIframe, isValidUrl]);

  useEffect(() => {
    const historyCB = makeHistoryCB(locale);
    return listenToHistoryWhenNeeded({
      isValidUrl,
      history,
      historyCB,
    });
  }, [isValidUrl, locale, history]);

  useEffect(() => {
    execBookingOriginActionsWhenNeeded({
      isValidUrl,
      isIframe,
      bookingEventSent,
      bookingOrigin,
      pathname: history.location.pathname,
      orderIsLoading,
    });
  }, [isValidUrl, isIframe, orderIsLoading, bookingOrigin, history]);
};

export default useGTMEvents;
