import { push as routerPush } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import paths from '../../navigation/paths';
import { mergeParameters } from '../booking/parameters';
import { fetchNoParams } from '../../lib/api';
import {
  PIR_INFO_FETCH, PIR_INFO_FETCH_ERROR, PIR_INFO_FETCH_SUCCESS,
} from './types';

export const pirInfoFetchRequest = () => ({
  type: PIR_INFO_FETCH,
});

export const pirInfoFecthSuccess = (payload) => ({
  type: PIR_INFO_FETCH_SUCCESS,
  payload,
});

export const pirInfoFecthError = (error) => ({
  type: PIR_INFO_FETCH_ERROR,
  error,
});

export const pirInfoFetch = (authData) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(pirInfoFetchRequest());

    const { reference } = authData;
    const url = `/order/service/pir/${reference}`;
    const pirInfo = await fetchNoParams(url);
    if (!pirInfo.length) {
      throw new Error('Pir not found');
    }

    dispatch(pirInfoFecthSuccess(pirInfo));
    dispatch(mergeParameters({
      pir_reference: reference,
    }));
    dispatch(routerPush(paths.pirInfo.summary));
  } catch (err) {
    dispatch(pirInfoFecthError('pirInfo.auth.errors.notFoundPIR'));
  }
  dispatch(hideLoading());
};
