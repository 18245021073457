const VALID_LANGS = ['es', 'en'];
const DEFAULT_LANG = 'es';
/**
 * Extracts the search parameters from given url
 * @param   {string} url
 * @returns {URLSearchParams}
 */
export const getSearchParams = (url) => {
  const searchParams = new URLSearchParams(url.split('?').pop());
  const marketer = searchParams.get('marketer');
  if (!searchParams.get('widget-theme') && marketer === 'IB') {
    searchParams.set('widget-theme', marketer);
  }
  const lang = searchParams.get('lang');
  if (!lang || !VALID_LANGS.includes(lang)) {
    searchParams.set('lang', DEFAULT_LANG);
  }
  return searchParams;
};

/**
 * Initializes message module shared between the widget and the integrator website
 * @param {window|iframe} param0.targetMessage  target to send message
 * @param {string}        param0.targetMessageOrigin origin
 * @param {window|iframe} param0.targetListener target to subscribe
 * @param {boobean}       param0.initialReady setups initial targetIsReady value
 */
const Messages = ({
  targetMessage,
  targetMessageOrigin,
  targetListener,
  initialReady = false,
}) => {
  let targetIsReady = initialReady;
  const messagesQueue = [];
  const sendMessage = (action, payload) => {
    const target = (typeof targetMessage === 'function' ? targetMessage() : targetMessage);
    target.postMessage({ action, payload }, targetMessageOrigin);
  };
  const subscribeToMessages = (handlers = {}, safeOrigin) => {
    const { origin } = new URL(safeOrigin);
    targetListener.addEventListener('message', (event) => {
      if (origin === event.origin) {
        const { data: { action, payload } } = event;
        const handler = handlers[action];
        if (typeof handler === 'function') {
          handler({ payload });
        }
      }
    });
  };
  const processQueueMessages = () => messagesQueue.forEach((args) => sendMessage(...args));
  const sendMessageTo = (...args) => (targetIsReady
    ? sendMessage(...args)
    : messagesQueue.push(args));
  const setReady = () => {
    targetIsReady = true;
    processQueueMessages();
  };
  return {
    sendMessageTo,
    subscribeToMessages,
    setReady,
  };
};

export default Messages;
