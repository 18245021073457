import styled from 'styled-components';
import { Button } from '@bagonboard/ui-kit';
import { font, mediaQueries, widths } from '../../styles/widths';
import palette from '../../styles/palette';

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 4px 75px 4px;
`;
Wrapper.displayName = 'Wrapper';

export const Container = styled.div`
    max-width: 400px;
    margin: 0 auto;
`;
Container.displayName = 'Container';

export const PopupModal = styled.div`
  text-align    : center;
  padding       : 20px;
  padding-bottom: 80px;
  & > div {
    width: 328px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${widths.base * 2}px;
  }
`;
PopupModal.displayName = 'PopupModal';

export const PopupText = styled.div`
  font-size  : 14px;
  font-weight: ${font.regular};
  text-align : center;
  color      : ${palette.bobBlack};
  margin-top : ${widths.base * 4}px;
`;
PopupText.displayName = 'PopupText';

export const AddNumberButton = styled(Button)`
  display: none;
  ${mediaQueries.desktop} {
    display: initial;
    width: 140px;
  }
`;
AddNumberButton.displayName = 'AddNumberButton';

export const PopupFooterButton = styled(Button)`
  display   : initial;
  left      : 0;
  position  : absolute;
  text-align: center;
  background: ${palette.primary};
  width     : 100%;
  bottom    : 0;
  color     : ${palette.white};
  margin-top: ${widths.base * 4}px;
  & p {
    font-size  : 14px;
    font-weight: ${font.medium};
    color      : ${palette.white};
  };
  ${mediaQueries.desktop} {
    display: none;
  }
`;
PopupFooterButton.displayName = 'PopupFooterButton';

export const ApartmentIcon = styled.div`
  display        : flex;
  justify-content: center;
`;

ApartmentIcon.displayName = 'ApartmentIcon';
