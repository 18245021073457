import { CLEAN_ADDRESS, SET_ADDRESS } from '../types';

export function setAddressField(name, value) {
  if (name === 'address') {
    return {
      type   : SET_ADDRESS,
      payload: value,
    };
  }
  return {
    type   : SET_ADDRESS,
    payload: {
      [name]: value,
    },
  };
}

export const cleanAddress = () => ({ type: CLEAN_ADDRESS });
