const initialState = {
  isIframe: false, // this is infered
  isWidget: false, // this is received in query string
};

export { initialState };

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
