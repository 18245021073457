/* eslint-disable func-names */
import * as yup from 'yup';
import moment from 'moment';
import { SERVICE_TYPES, REGEX } from '@bagonboard/wiseman';

import CONSTANTS from '../../../../lib/constants/index';
import axios from '../../../../lib/axios';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const coerceArrayNoDuplicates = (_, originalValue) => originalValue
  && (Array.isArray(originalValue)
    ? [...new Set(originalValue)]
    : [originalValue]);

/**
 * Date validator to work with the calendar selector
 */
const dateValidation = ({ format = 'YYYY-MM-DD', max = 10 } = {}) => yup
  .string()
  .max(max)
  .test({
    name     : 'dateValidation',
    exclusive: true,
    params   : { format },
    // eslint-disable-next-line no-template-curly-in-string
    message  : '${path} must be after than today',
    test     : (value) => (
      !value
    || moment(value, format).isSameOrAfter(moment({ h: 0, m: 0, s: 0 }))
    ),
  });

const common = {
  iata                    : yup.string().matches(/[A-Z]+/g).min(2).max(3),
  alphanumeric            : yup.string().matches(/[a-zA-Z0-9]+/g),
  alphanumericHyphens     : yup.string().matches(/[a-zA-Z0-9\s_-]+/g),
  alphanumericSpaceHyphens: yup.string().matches(/[a-zA-Z0-9_-]+/g),
  alphanumericHyphensAtDot: yup.string().matches(/[a-zA-Z0-9@._-]+/g),
  flightcode              : yup.string().matches(/^([a-z|A-Z]+)([0-9]{3,})$/),
  url                     : !isProduction ? yup.string() : yup.string().url(),
  languages               : yup.string().oneOf(CONSTANTS.availableLanguages),
  date                    : dateValidation(),
  lazyStringOrArray       : (schema) => yup.lazy((value) => (Array.isArray(value)
    ? yup.array().of(schema)
    : schema)),
};

const findParameterInInnerErrors = (inner, key) => !inner.find(
  ({ path, params: { unknown } }) => path?.split('[').shift() === key || unknown === key
);

const removeParametersWithError = (error) => {
  const { value, errors, inner = [] } = error;
  const parameters = Object.entries(value).reduce(
    (all, [key, innerValue]) => ({
      ...all,
      ...(findParameterInInnerErrors(inner, key) && {
        [key]: innerValue,
      }),
    }),
    {}
  );
  return { parameters, errors };
};

yup.addMethod(yup.mixed, 'getParameters', async function (value, context) {
  let parameters;
  let errors;
  const { fields: { validation, coercion } } = this;
  try {
    const args = [
      value,
      {
        context,
        abortEarly  : false,
        recursive   : true,
        stripUnknown: true,
      },
    ];
    await validation.noUnknown().strict().validate(...args);
    parameters = validation.noUnknown().cast(...args);
  } catch (error) {
    ({ parameters, errors } = removeParametersWithError(error));
  }
  return {
    parameters: coercion.cast(parameters, { context }),
    errors,
  };
});

yup.addMethod(yup.mixed, 'requiredIsIframe', function () {
  return this.when('$isIframe', {
    is       : true,
    then     : (obj) => obj.required(),
    otherwise: (obj) => obj.notRequired(),
  });
});

yup.addMethod(yup.string, 'widgetOwner', function (message) {
  return this.when('$isIframe', {
    is  : true,
    then: (obj) => obj.test(
      'widgetOwner',
      'Wrong widget-owner',
      function (widgetOwner) {
        const { path, createError } = this;
        axios.init(true, { 'widget-owner': widgetOwner });
        const instance = axios.getBackendInstance(15000);
        const urlWidgetOwnerCheck = '/widget/owner';
        return instance.post(urlWidgetOwnerCheck)
          .then((response) => {
            const { status, data: { code } } = response;
            if (status !== 200 || code !== 200) {
              return createError({ path, message });
            }
            return true;
          }).catch((error) => createError({ path, message: error.message }));
      },
    ),
  });
});

yup.addMethod(yup.mixed, 'validateDate', function () {
  return this.when('$date', {
    is       : true,
    then     : (obj) => obj.required(),
    otherwise: (obj) => obj.notRequired(),
  });
});

export const validationSchema = yup.object().shape({
  affiliate                       : common.alphanumericHyphens.min(2).max(50).requiredIsIframe(),
  marketer                        : common.iata,
  operator                        : common.iata,
  airline                         : common.alphanumericHyphens.min(3).max(50),
  lang                            : common.languages,
  target                          : common.url.max(100).requiredIsIframe(),
  type_checkin                    : common.alphanumericHyphens.min(3).max(50),
  field1                          : common.alphanumericSpaceHyphens.min(3).max(80), // PNR
  field2                          : common.alphanumericHyphensAtDot.min(3).max(100), // SURNAME
  date                            : common.date,
  from                            : common.iata,
  to                              : common.iata,
  landing                         : common.alphanumericHyphens.min(1).max(30),
  'widget-airlines'               : common.lazyStringOrArray(common.iata),
  'widget-owner'                  : yup.string().uuid().requiredIsIframe().widgetOwner(),
  'widget-theme'                  : common.alphanumeric.min(2).max(10),
  'widget-load-hidden'            : yup.bool(),
  'widget-denied-external-cookies': yup.bool(),
  widget                          : yup.bool(),
  token                           : common.alphanumeric.length(32),
  order                           : common.alphanumeric.length(24),
  biometric                       : common.alphanumeric.length(24),
  service_type                    : yup.string().oneOf(Object.values(SERVICE_TYPES)),
  pir_reference                   : yup.string().matches(REGEX.pir),
  force_operator                  : yup.bool(),
  flightcode                      : common.flightcode,
});

const coercionSchema = yup.object().shape({
  'widget-airlines': yup.array().transform(coerceArrayNoDuplicates),
});

const schema = yup.object().shape({
  validation: validationSchema,
  coercion  : coercionSchema,
});

export default schema;
