import { useRef, useEffect } from 'react';
import { pipe } from '@bagonboard/wiseman';

import {
  pages, preloadAllRoutes,
} from '../navigation';

const preloadPages = pipe(
  Object.values,
  preloadAllRoutes
);

let preloadTimeout;

export const makePreloader = ({
  isValidUrl,
  isPreloaded,
}) => {
  // Do the preload only the first time we arrive to a good page
  if (isValidUrl && !isPreloaded.current) {
    const doPreload = () => {
      preloadPages(pages);
      // eslint-disable-next-line no-param-reassign
      isPreloaded.current = true;
    };
    preloadTimeout = setTimeout(doPreload, 250);
  }
  return () => {
    if (preloadTimeout) {
      clearTimeout(preloadTimeout);
    }
  };
};

const useRoutesPreloader = (isValidUrl) => {
  // We want to store the flag in a reference, to persist its value even in the case of
  // the component reloads for some strange reason
  const isPreloaded = useRef(false);
  useEffect(() => makePreloader({
    isValidUrl,
    isPreloaded,
  }),
  [isValidUrl]);
};

export default useRoutesPreloader;
