import initMessages, { getSearchParams } from  './common';

let windowProvider = window;
let documentProvider = document;

/**
 * Used to initialize the messages fns based on the document and window providers
 */
function initMessagesFns() {
  const uri = documentProvider.baseURI || documentProvider.documentURI;
  const targetMessageOrigin = getSearchParams(uri).get('target');
  return initMessages({
    targetMessage : windowProvider.parent,
    targetMessageOrigin,
    targetListener: windowProvider,
    initialReady  : true,
  });
}

let {
  sendMessageTo: sendMessageToInternal,
  subscribeToMessages: subscribeToMessagesInternal,
} = initMessagesFns();

/**
 * Used to bypass the initial window and document providers
 * @param {Object} newWindow
 * @param {Object} newDocument
 */
export const init = (newWindow, newDocument) => {
  if (process.env.NODE_ENV !== 'test') {
    return;
  }
  windowProvider = newWindow;
  documentProvider = newDocument;
  ({ sendMessageToInternal, subscribeToMessagesInternal } = initMessagesFns());
};

/**
 * Helper to determine if the booking is running inside an iframe
 */
export const isIframe = () => {
  if (!windowProvider) {
    return true;
  }
  try {
    return windowProvider !== windowProvider.parent;
  } catch (e) {
    return true;
  }
};

// Wrapped messages fns
export const sendMessageTo = (...args) => sendMessageToInternal(...args);
export const subscribeToMessages = (...args) => subscribeToMessagesInternal(...args);
