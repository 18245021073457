import { get as getCookie, set as setCookie } from 'js-cookie';

import { fetchPost, fetchNoParams } from '../../../lib/api';
import { routerPush } from '../../../lib/redirections';
import * as types from '../types';
import {
  serviceMarketerSelector,
  flightFirstProductIdSelector,
  flightDataSelector,
  flightServicesSelector,
  flightSelectedProductSelector,
} from './selectors';
import paths from '../../../navigation/paths';
import { fetchFlight } from '../../data/actions';
import {
  pushServiceListToGTM,
  pushOriginAndDestinationAirportToGTM,
  pushDaysInAdvanceToGTM,
  pushServiceTypeToGTM,
  pushRouteToGTM,
  pushServiceOpportunitiesToGTM,
  pushFlightSelection,
} from '../../../navigation/pages/booking/gtm';

import { ENDPOINTS } from './constants';

const validTypes = ['checkin', 'transfer'];

export function setBookingParameters(params) {
  const {
    airline,
    affiliate,
    fromCode,
    landing,
    marketer,
    operator,
    toCode,
    type,
    typeCheckin,
    departureDate,
    serviceType,
    // eslint-disable-next-line camelcase
    pir_reference,
  } = params;
  return (dispatch) => {
    const newState = {
      airline,
      affiliate,
      fromCode,
      landing,
      marketer,
      operator,
      toCode,
      // TODO: Check use
      type: validTypes.indexOf(type) !== -1 ? type : 'transfer',
      typeCheckin,
      departureDate,
      serviceType,
      pir_reference,
    };
    dispatch({
      type   : types.SET_BOOKING_PARAMETERS,
      payload: newState,
    });
  };
}

export function setBookingType(type) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_BOOKING_TYPE,
      payload: validTypes.indexOf(type) !== -1 ? type : 'transfer',
    });
  };
}

export function setBookingDeliveryTime({ deliveryTime, timeSlot, timezone }) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_BOOKING_DELIVERY_TIME,
      payload: { deliveryTime, timeSlot, timezone },
    });
  };
}

export function setBookingPickupTime({ pickupTime, timeSlot, timezone }) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_BOOKING_PICKUP_TIME,
      payload: { pickupTime, timeSlot, timezone },
    });
  };
}

export function setDepartureDate(date) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_DEPARTURE_DATE,
      payload: date,
    });
  };
}

export function setBusinnessFare(value) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_FREE,
      payload: value,
    });
    dispatch({
      type   : types.SET_BUSINESS_FARE,
      payload: value,
    });
  };
}

// TODO: CHECK IF IT IS DEPRECATED
export function setFlight(flight) {
  return (dispatch) => {
    const { code } = flight;
    const flightResult = {};
    flightResult[code] = { ...flight };
    dispatch({
      type   : types.SET_FLIGHT,
      payload: flightResult,
    });
  };
}

export function setSelectedProduct(productId) {
  return async (dispatch, getState) => {
    await dispatch({
      type   : types.SET_SELECTED_PRODUCT,
      payload: productId,
    });
    const state = getState();
    const { service } = flightSelectedProductSelector(state);
    pushServiceTypeToGTM(service);
    return dispatch(fetchFlight());
  };
}

export function setSelectedFlight(selectedFlightKeys) {
  return async (dispatch, getState) => {
    await dispatch({
      type   : types.SET_SELECTED_FLIGHT_KEYS,
      payload: selectedFlightKeys,
    });
    const state = getState();
    const marketer = serviceMarketerSelector(state);
    const {
      origin: { airportCode: originAirport, date },
      destination: { airportCode: destinationAirport, date: destinationDate },
      operator,
    } = flightDataSelector(state);
    const services = flightServicesSelector(state);
    pushServiceListToGTM(services);
    pushOriginAndDestinationAirportToGTM(originAirport, destinationAirport);
    pushDaysInAdvanceToGTM(date);
    pushRouteToGTM(originAirport, destinationAirport, marketer, operator);
    pushFlightSelection({
      originAirport,
      destinationAirport,
      marketer,
      operator,
      date,
      destinationDate,
    });
    if (services.length === 0) {
      // Redirect to not available and show flight information on the next screen
      return dispatch(fetchFlight());
    }
    pushServiceOpportunitiesToGTM(services);
    // when it has only one product, select that product
    if (services.length === 1) {
      const productId = flightFirstProductIdSelector(state);
      return dispatch(setSelectedProduct(productId));
    }
    // when it is a multiservice, redirect to service list
    return dispatch(routerPush(paths.routerServices));
  };
}

export function setPaxBags(pax, numBags) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_PAX_BAGS,
      payload: { pax, numBags },
    });
  };
}

export function setBagComment(comment) {
  return (dispatch) => {
    dispatch({
      type   : types.SET_BAG_COMMENT,
      payload: comment,
    });
  };
}

export function setDowngrade() {
  return (dispatch) => {
    dispatch({
      type   : types.SET_DOWNGRADE,
      payload: true,
    });
  };
}

export function resetDowngrade() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_DOWNGRADE,
    });
  };
}

export function setReferral(referral) {
  return (dispatch) => {
    setCookie('referral', referral, { expires: 3 });
    dispatch({
      type   : types.SET_REFERRAL,
      payload: referral,
    });
  };
}

export function setPaymentType() {
  return async (dispatch, getState) => {
    try {
      const {
        booking: {
          order: { referral },
          service: { flightClass, typeCheckin },
        },
        data: {
          flightInfo: { pnr },
        },
      } = getState();
      const cReferral = getCookie('referral');
      const body = {
        flightClass,
        referral: referral || cReferral || undefined,
      };
      if (typeCheckin) {
        body.tier = typeCheckin;
      }
      const isFree = pnr
        ? await fetchPost('/order/exception', body)
        : ['vipcustomer', 'business'].includes(typeCheckin); // TODO: improve this and retrieve "free" values from backend when is manual checkin
      if (isFree) {
        dispatch({
          type   : types.SET_FREE,
          payload: isFree,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_FREE_ERROR,
      });
    }
  };
}

export function getBiometricById(id, token) {
  return async (dispatch) => {
    await dispatch({ type: types.SERVICE_BIOMETRIC_REQUEST });
    const result = await fetchNoParams(`${ENDPOINTS.getBiometric}${id}?token=${token}`);
    await dispatch({
      type   : types.SERVICE_BIOMETRIC_SUCCESS,
      payload: result,
    });
    return result;
  };
}
