import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { get as getCookie } from 'js-cookie';
import App from './App';
import syncTranslationWithStore from './statics/locales';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import rootReducer from './reducers';
import history from './history';
import GTM from './lib/GTM';
import { initializeSentry, getSentryReduxMiddleware } from './lib/sentry';
import axios from './lib/axios';
import initStore from './store';
import { isIframe as checkIsIframe } from './lib/widget';
import parseAndFilterParameters from './lib/queryString/parseFilter';
import parametersSchema from './statics/validations/booking/parameters';
import applyIntegratorTheme from './statics/integrator-themes';

(async () => {
  const WidgetErrorParameters = React.lazy(() => import('./components/Widget/Error'));

  const isProduction = process.env.NODE_ENV === 'production';
  const isIframe = checkIsIframe();
  const parsedParameters = parseAndFilterParameters(
    window.location.search,
    isIframe
  );
  const {
    parameters,
    errors: parametersErrors,
  } = await parametersSchema.getParameters(parsedParameters, { isIframe });

  initializeSentry();
  axios.init(isIframe, parameters);
  const store = initStore({
    widget : { isIframe },
    booking: { parameters },
  },
  [getSentryReduxMiddleware]);
  syncTranslationWithStore(store);

  const tagManagerArgs = {
    id     : process.env.REACT_APP_GTM_KEY,
    auth   : process.env.REACT_APP_GTM_ENVIRONMENT,
    preview: process.env.REACT_APP_GTM_AUTH,
  };

  const isCloudFront = window.location.hostname.endsWith('.cloudfront.net');
  const externalCookiesDenied = getCookie('hasDeniedExternalCookies') || parameters['widget-denied-external-cookies'];
  if (isCloudFront) {
    window.location.assign(process.env.REACT_APP_HOMEPAGE);
  } else {
    GTM.init(tagManagerArgs, externalCookiesDenied);
  }

  if (parametersErrors) {
    // eslint-disable-next-line no-console
    parametersErrors.forEach((error) => console.error('parameter validation - ', error));
  }

  // Integrator widget theme
  if (parameters['widget-theme']) {
    applyIntegratorTheme(parameters['widget-theme']);
  }

  const render = () => {
    ReactDOM.render(
      isCloudFront
        ? (<h1>not allowed</h1>)
        : (isIframe && parametersErrors && !isProduction)
          ? (
            <React.Suspense fallback={<div>Loading...</div>}>
              <WidgetErrorParameters errors={parametersErrors} />
            </React.Suspense>
          )
          : (
            <Provider store={store}>
              <App history={history} parameters={parameters} />
            </Provider>
          ),
      document.getElementById('root')
    );
    unregisterServiceWorker();
  };
  render();

  // Hot reloading
  if (module.hot) {
  // Reload components
    module.hot.accept('./App', () => {
      render();
    });

    // Reload reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }
})();
