import { createSelector } from 'reselect';
import { defaultTo, pipe, prop } from '@bagonboard/wiseman';

export const customerSelector = (state) => state.booking.customer;

export const customerSurnameSelector = pipe(
  customerSelector,
  prop('surname')
);

export const safeCustomerSelector = createSelector(
  customerSelector,
  defaultTo({}),
);
