import Color from 'color';

export default {
  primary     : '#e83f40',
  primaryHover: `${Color('#e83f40').darken(0.2)}`,
  disabled    : '#c5c1c1',
  default     : '#4a4a4a',
  link        : '#4382e5',
  error       : '#af0019',
  success     : '#39a472',
  // Colors
  black       : '#000000',
  bobBlack    : '#4a4a4a',
  grey        : '#949494',
  transparent : 'rgba(0,0,0,0)',
  white       : '#ffffff',
  darkWhite   : '#efefef',
  warning     : '#fdd000',
  secondary   : '#5fafc1',
  gradLight   : '#009dc1',
  gradDark    : '#78b5c2',
};
