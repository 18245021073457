import { get as getCookie, set as setCookie } from 'js-cookie';

const useAffiliateLoader = ({ history }) => {
  const hasAffiliatePluginActive = getCookie('bob_has_affiliate_plugin_active');
  if (!hasAffiliatePluginActive) return undefined;
  const affiliateCookie = getCookie('affiliate');
  if (affiliateCookie) return affiliateCookie;
  const queryString = history.location.search;
  const urlParams = new URLSearchParams(queryString);
  const affiliate = urlParams.get('affiliate');
  if (affiliate) {
    setCookie('affiliate', affiliate, { expires: 7 });
    return affiliate;
  }
  return undefined;
};

export default useAffiliateLoader;
