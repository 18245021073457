import { pipe, prop, propOr } from '@bagonboard/wiseman';

export const flightInfoSelector = (state) => state.data.flightInfo;

export const flightDataSelector = pipe(
  flightInfoSelector,
  propOr({}, 'flightData'),
);

export const flightOperatorSelector = pipe(
  flightDataSelector,
  prop('operator'),
);

export const flightOriginSelector = pipe(
  flightDataSelector,
  prop('origin'),
);

export const flightFromCodeSelector = pipe(
  flightOriginSelector,
  prop('airportCode'),
);

export const flightDestinationSelector = pipe(
  flightDataSelector,
  prop('destination'),
);

export const flightToCodeSelector = pipe(
  flightDestinationSelector,
  prop('airportCode'),
);
