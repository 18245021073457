import * as types from '../types';

const initialState = {
  name                   : null,
  surname                : null,
  email                  : null,
  phone                  : null,
  language               : 'es',
  checkedConditions      : false,
  checkedSubscribeUpdates: false,
};

export const customer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_CUSTOMER_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case types.SET_CUSTOMER_SURNAME:
      return {
        ...state,
        surname: action.payload,
      };
    case types.SET_CUSTOMER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case types.SET_CUSTOMER_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case types.SET_CUSTOMER_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.SET_CHECKED_CONDITIONS:
      return {
        ...state,
        checkedConditions: action.payload,
      };
    case types.SET_CHECKED_SUBSCRIBE_UPDATES:
      return {
        ...state,
        checkedSubscribeUpdates: action.payload,
      };
    default:
      return state;
  }
};
